import React, { useEffect, useState } from 'react';
import { Grid, FormLabel, FormControlLabel, CardMedia, IconButton, Typography, Button, useTheme, useMediaQuery, List, ListItem, ListItemText, Divider, Box, Autocomplete, FormControl, Avatar, TextField, Card, CardContent, MenuItem, Select, InputLabel, Radio, RadioGroup } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Slider from 'react-slick';
import { formatDate, formatPrice, primaryColor, primaryColorHover } from '../constant';
import { get_data, serverURL } from '../api';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import { FaAmazon } from 'react-icons/fa'
import { SiFlipkart } from "react-icons/si";
import SvgIcon from '@mui/material/SvgIcon';
import { ShoppingBag, SimCard } from '@mui/icons-material';
import { ShoppingCart } from '@mui/icons-material'
import { Download } from '@mui/icons-material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const ProductComp = ({ product }) => {
    const [accessoriesList, setAccessoriesList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPrice, setTotalPrice] = useState(product?.price);
    const [relay, setRelay] = useState('no relay');
    const [simCard, setSimCard] = useState('no sim');
    const [server, setServer] = useState('without server');
    const [insurance, setInsurance] = useState('without insurance');
    const [globalPrices, setGlobalPrices] = useState({});
    const [formData, setFormData] = useState({
        accessories: []
    })
    const [selectedAccessories, setSelectedAccessories] = useState([])
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false)
    const displayKeys = [
        'type',
        'model',
        'trackingDeviceType',
        'trackingMethod',
        'gpsTrackerType',
        'serviceCompletion',
        'maintenanceType',
        'frequency',
        'color',
        'warranty',
    ];
    const theme = useTheme();
    const matches1 = useMediaQuery(theme.breakpoints.down(1100));
    const matches3 = useMediaQuery(theme.breakpoints.down(500));
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const [mainImage, setMainImage] = useState(null);
    const [qty, setQty] = useState(0);

    const fetchSelectedAccessories = () => {
        let accessoriesIds = JSON.parse(localStorage.getItem('accessoryId'))
        if (accessoriesIds?.[product?._id]) {
            setSelectedAccessories(accessoriesIds?.[product?._id])
        }
    }

    useEffect(function () {
        fetchSelectedAccessories();
    }, [refresh])

    useEffect(() => {
        setMainImage(product?.images?.[0]);
    }, [product]);

    const styles = {
        arrow: {
            borderRadius: '50%',
            padding: '5px',
            cursor: 'pointer',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        requestButton: {
            background: primaryColor,
            borderRadius: 0,
            color: 'white',
            width: '100%',
            padding: '3% 0',
        },
        cartButton: {
            width: '20%',
            marginLeft: '5px',
            borderColor: primaryColor,
            color: primaryColor,
            textTransform: 'capitalize',
            fontWeight: 400,
            boxShadow: 'none',
            borderRadius: 2,
            '&:hover': {
                borderColor: primaryColor,
                boxShadow: 'none',
            },
            '&:focus': {
                outline: 'none',
                boxShadow: 'none',
                borderColor: primaryColor,
            }
        },
        buyButton: {
            width: '50%',
            background: primaryColor,
            color: 'white',
            borderRadius: 0,
            fontWeight: 400,
            textTransform: 'capitalize',
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: primaryColorHover,
                boxShadow: 'none'
            },
            '&:focus': {
                outline: 'none',
                boxShadow: 'none',
                backgroundColor: primaryColor,
            }
        },
        linkButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // padding: '5px',
            fontWeight: 'bold',
            textTransform: 'none',
            borderRadius: '10px',
        },
        amazon: {
            borderColor: '#FF9900',
            color: '#FF9900',
            '&:hover': {
                backgroundColor: '#FF990010'
            }
        },
        flipkart: {
            borderColor: '#2874F0',
            color: '#2874F0',
            '&:hover': {
                backgroundColor: '#2874F010'
            }
        },
        meesho: {
            borderColor: '#FF00B5',
            color: '#FF00B5',
            '&:hover': {
                backgroundColor: '#FF00B510'
            }
        },
        snapdeal: {
            borderColor: '#E40046',
            color: '#E40046',
            '&:hover': {
                backgroundColor: '#E4004610'
            }
        },
        card: {
            // border: '1px solid #eee',
            width: 'fit-content',
            borderRadius: '12px',
            textAlign: 'center',
            // padding: '5px',
            transition: 'all 0.3s ease',
            '&:hover': {
                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)'
            }
        }

    };

    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <IconButton
                onClick={onClick}
                style={{ ...styles.arrow, right: '-50px', position: 'absolute', top: '30%' }}
            >
                <ArrowForwardIosIcon style={{ fontSize: matches3 ? '1.8rem' : '2.2rem' }} />
            </IconButton>
        );
    };

    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <IconButton
                onClick={onClick}
                style={{ ...styles.arrow, left: '-50px', position: 'absolute', top: '30%' }}
            >
                <ArrowBackIosIcon style={{ fontSize: matches3 ? '1.8rem' : '2.2rem' }} />
            </IconButton>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 1200,
        autoplay: true,
        slidesToShow: matches1 ? 3 : 4,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    const getAllAccessoriesList = async () => {
        let result = await get_data(`accessory/get-all-accessories?pageNumber=${currentPage}`);

        let global = await get_data('global/get-global-prices');

        if (global?.status == true) {
            setGlobalPrices(global?.data);
        }

        if (result?.status == true) {
            setAccessoriesList(result?.data?.accessories);
        }


        const productInfo = JSON.parse(localStorage.getItem("productInfo")) || {};

        Object.keys(productInfo).map((key) => {
            if (key == product?._id) {
                setTotalPrice(productInfo[key]?.totalPrice);
                setFormData({ ...formData, accessory: productInfo[key]?.accessory });
                setRelay(productInfo[key]?.relay);
                setServer(productInfo[key]?.server);
                setInsurance(productInfo[key]?.insurance);
                setSimCard(productInfo[key]?.simCard);
                setQty(productInfo[key]?.qty);
            }
        })
    }


    React.useEffect(function () {
        if (currentPage) {
            getAllAccessoriesList();
        }
    }, [currentPage, refresh])

    const showSlider = () => {
        return product?.images?.map((item, index) => (
            <div key={index} style={{ width: '100%' }}>
                <img
                    style={{
                        width: '90%',
                        cursor: 'pointer',
                        border: '1px solid gainsboro',
                        aspectRatio: '1 / 1',
                        objectFit: 'contain',
                    }}
                    src={`${serverURL}/uploads/products-images/${item}`}
                    alt={`Product ${index + 1}`}
                    onClick={() => setMainImage(item)}
                />
            </div>
        ));
    };

    function camelToNormal(str) {
        // Insert a space before each uppercase letter and convert the whole string to lowercase
        return str
            .replace(/([A-Z])/g, ' $1')    // Insert space before uppercase letters
            .toLowerCase()                 // Convert the entire string to lowercase
            .replace(/^./, str => str.toUpperCase()); // Capitalize the first letter
    }

    const handleAddToCart = () => {
        setQty(1);
        const productIds = JSON.parse(localStorage.getItem("productId")) || [];
        const productInfo = JSON.parse(localStorage.getItem("productInfo")) || {};

        if (!productIds.includes(product?._id)) {
            productIds.push(product?._id);
        }

        localStorage.setItem("productInfo", JSON.stringify({ ...productInfo, [product?._id]: { relay: relay, insurance: insurance, server: server, simCard: simCard, totalPrice: totalPrice, accessory: formData?.accessory, qty: qty } }));

        localStorage.setItem("productId", JSON.stringify(productIds));

    };

    const handleCheckout = () => {

        const productInfo = JSON.parse(localStorage.getItem("productInfo")) || {};

        localStorage.setItem("productInfo", JSON.stringify({ ...productInfo, [product?._id]: { relay: relay, insurance: insurance, server: server, simCard: simCard, totalPrice: totalPrice, accessory: formData?.accessory, qty: qty } }));

        navigate("/checkout")
    }

    const handleSelectAccessories = (accessory) => {
        const accessoryIds = JSON.parse(localStorage.getItem("accessoryId")) || {};

        // Set the selected accessory's ID for this product
        accessoryIds[product?._id] = accessory?._id;

        // Save updated accessory data back to localStorage
        localStorage.setItem("accessoryId", JSON.stringify(accessoryIds));

        // Trigger a re-render (if necessary)
        setRefresh(!refresh);
    };

    const handleDownloadExcel = async () => {
        const pdfFile = product?.images?.find(item => item.includes(".pdf"));

        if (pdfFile) {
            const pdfUrl = `${serverURL}/uploads/products-images/${pdfFile}`;

            try {
                const response = await fetch(pdfUrl);
                const blob = await response.blob();

                // Create a link element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', 'product-file.pdf'); // Set the download file name

                // Append the link to the document body
                document.body.appendChild(link);

                // Programmatically click the link to trigger download
                link.click();

                // Clean up
                window.URL.revokeObjectURL(link.href); // Release the object URL
                document.body.removeChild(link); // Remove the link element
            } catch (error) {
                console.log("Error downloading file:", error);
            }
        } else {
            console.log("No PDF found in the product images.");
        }
    };

    const showMenu = () => {
        return (
            <>
                <MenuItem value="12v">12V</MenuItem>
                <MenuItem value="24v">24V</MenuItem>
            </>
        )
    }

    return (
        <div style={{ padding: matches_md ? '1%' : '2% 4% 0' }}>
            <Grid container spacing={4} style={{ padding: 10, marginBottom: 10, }}>
                {/* Left Side - Images */}
                <Grid item xs={12} md={6} style={{ marginTop: matches3 ? 5 : 20, overflowY: matches_md ? '' : 'scroll', height: matches_md ? 'auto' : '100vh', scrollbarWidth: 'none' }} >
                    <Grid container spacing={2} style={{ width: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <CardMedia
                                style={{
                                    width: matches3 ? '80%' : '60%',
                                    aspectRatio: '1 / 1',
                                    objectFit: 'contain',
                                }}
                                component="img"
                                image={`${serverURL}/uploads/products-images/${mainImage}`}
                                alt="Product Image"
                            />
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Slider {...settings} style={{ width: '80%' }}>
                                {showSlider()}
                            </Slider>
                        </Grid>

                    </Grid>
                </Grid>

                {/* Right Side - Product Info */}
                <Grid item xs={12} md={6} padding={matches3 ? '' : 10} style={{ marginTop: matches3 ? 5 : 20, overflowY: matches_md ? '' : 'scroll', height: matches_md ? 'auto' : '85vh', scrollbarWidth: 'none' }}>
                    <Typography variant="h5" component="h1" style={{ fontWeight: 500, display: 'flex', justifyContent: 'space-between' }} >
                        {product?.brand} {product?.name}
                        {/* <a href={`${serverURL}/uploads/products-images/06e81e66-c287-4730-b3f5-9e2e12ab9cb9.pdf`} download >download</a> */}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        By Oredo GPS
                    </Typography>

                    {/* Price Section */}
                    <Grid container alignItems="center" spacing={1} sx={{ marginTop: 2 }}>
                        <Grid item>
                            <Typography variant="h4" style={{ color: primaryColor, fontWeight: 500 }}>
                                {formatPrice(totalPrice)}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                        In stock: Dispatch in 5 working days
                    </Typography>

                    <Grid item md={12}>
                        <FormControl sx={{ width: '100%', marginTop: '10px' }}>
                            {/* <InputLabel id="accessories-select-label">Select Accessory</InputLabel> */}
                            <Select
                                value={formData?.accessory || ''}
                                onChange={(e) => {
                                    if (e.target.value === '') {
                                        setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(formData?.accessory?.price));
                                        setFormData((prevData) => ({
                                            ...prevData,
                                            accessory: null,
                                        }));
                                        handleSelectAccessories(null, false);
                                    } else {
                                        const selectedAccessory = product?.accessories.find((item) => item.title === e.target.value);

                                        setFormData((prevData) => ({
                                            ...prevData,
                                            accessory: selectedAccessory,
                                        }));

                                        if (formData?.accessory?.price) {
                                            setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(formData?.accessory?.price) + parseInt(selectedAccessory?.price));
                                        } else {
                                            setTotalPrice((prevPrice) => parseInt(prevPrice) + parseInt(selectedAccessory?.price));
                                        }

                                        handleSelectAccessories(selectedAccessory, true);
                                    }
                                }}
                                displayEmpty
                                renderValue={(selected) =>
                                    selected ? selected.title : 'Select Accessories'
                                }
                            >
                                <MenuItem value="">
                                    Select Accessories
                                </MenuItem>
                                {product?.accessories?.map((item, i) => (
                                    <MenuItem key={i} value={item.title}>
                                        {item.title}  (${formatPrice(item?.price)})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {

                        product?.relay?.find((item) => item === 'no relay') ? ''
                        :
                            product?.relay?.includes('12v') && product?.relay?.includes('24v') ? (
                                <Grid item md={12}>
                                    <FormControl fullWidth sx={{ mt: 2 }}>
                                        <InputLabel>Relay</InputLabel>
                                        <Select
                                            value={relay}
                                            onChange={(e) => {
                                                if (e.target.value === 'no relay') {
                                                    setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(globalPrices?.relay));
                                                } else if (relay != 'no relay') {
                                                    // setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(globalPrices?.relay));
                                                }else {
                                                    setTotalPrice((prevPrice) => parseInt(prevPrice) + parseInt(globalPrices?.relay));
                                                }
                                                setRelay(e.target.value);
                                            }}
                                            label="Relay"
                                        >
                                            <MenuItem value="no relay">No Relay</MenuItem>
                                            <MenuItem value="12v">12V</MenuItem>
                                            <MenuItem value="24v">24V</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )
                            :
                            <Grid item md={12}>
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel>Relay</InputLabel>
                                    <Select
                                        value={relay}
                                        onChange={(e) => {
                                            if (e.target.value === 'no relay') {
                                                setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(globalPrices?.relay));
                                            } else if (relay != 'no relay') {
                                                // setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(globalPrices?.relay));
                                            } else {
                                                setTotalPrice((prevPrice) => parseInt(prevPrice) + parseInt(globalPrices?.relay));
                                            }
                                            setRelay(e.target.value);
                                        }}
                                        label="Relay"
                                    >
                                        <MenuItem value="no relay">No Relay</MenuItem>
                                        {
                                            product?.relay?.includes('12v') ? (
                                                <MenuItem value="12v">12V</MenuItem>
                                            ) : product?.relay?.includes('24v') ? (
                                                <MenuItem value="24v">24V</MenuItem>
                                            ) : null
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>}

                    {/* <Grid item md={12}>

                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>SIM Card</InputLabel>
                            <Select
                                value={simCard}
                                onChange={(e) => {
                                    setSimCard(e.target.value);
                                    if (e.target.value === 'no sim') {
                                        setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(globalPrices?.simCard));
                                    } else if (simCard != 'no sim') { }
                                    else {
                                        setTotalPrice((prevPrice) => parseInt(prevPrice) + parseInt(globalPrices?.simCard));
                                    }
                                }}
                                label="SIM Card"
                            >
                                <MenuItem value="no sim">No Sim</MenuItem>
                                <MenuItem value="airtel">Airtel</MenuItem>
                                <MenuItem value="idea">Idea</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid> */}

                    <Grid item md={12}>
                        <FormControl sx={{ mt: 2 }} fullWidth>
                            <InputLabel>Server</InputLabel>
                            <Select
                                value={server}
                                onChange={(e) => {
                                    setServer(e.target.value);
                                    if (e.target.value === 'with server') {
                                        setTotalPrice((prevPrice) => parseInt(prevPrice) + parseInt(globalPrices?.server));
                                    } else {
                                        setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(globalPrices?.server));
                                    }
                                }}
                                label="Server"
                            >
                                <MenuItem value="without server">Without Server</MenuItem>
                                <MenuItem value="with server">With Server</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item md={12}>
                        <FormControl sx={{ mt: 2 }} fullWidth>
                            <InputLabel>Insurance</InputLabel>
                            <Select
                                value={insurance}
                                onChange={(e) => {
                                    setInsurance(e.target.value);
                                    if (e.target.value === 'with insurance') {
                                        setTotalPrice((prevPrice) => parseInt(prevPrice) + parseInt(globalPrices?.insurance));
                                    } else {
                                        setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(globalPrices?.insurance));
                                    }
                                }}
                                label="Insurance"
                            >
                                <MenuItem value="without insurance">Without Insurance</MenuItem>
                                <MenuItem value="with insurance">With Insurance</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Buttons */}
                    <Grid container spacing={2} sx={{ marginTop: 2 }}>
                        <Grid item xs={12} sx={{ display: 'flex', gap: '10px' }}>
                            {
                                qty === 0 ?
                                    <Button variant="contained" sx={styles.buyButton} onClick={handleAddToCart}>Add to Cart</Button>
                                    :
                                    <>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 2,
                                                border: '1px solid #ccc',
                                                borderRadius: '8px',
                                                padding: '8px',
                                                width: 'fit-content',
                                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            }}
                                        >
                                            <IconButton onClick={() => setQty(qty + 1)} color="primary">
                                                <AddIcon />
                                            </IconButton>
                                            <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                                {qty}
                                            </Typography>
                                            <IconButton onClick={() => {
                                                if (qty > 0) setQty(qty - 1);
                                            }}
                                                color="secondary">
                                                <RemoveIcon />
                                            </IconButton>
                                        </Box>

                                        <Button variant="outlined" sx={styles.cartButton} onClick={handleCheckout}>Checkout</Button>
                                    </>
                            }
                            {/* <Button variant="contained" sx={styles.buyButton} onClick={handleBuyNow}>Buy Now</Button> */}
                        </Grid>
                    </Grid>

                    {/* Product Details Section */}
                    <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                        <List sx={{ border: '1px solid gainsboro' }}>
                            {Object.entries(product)
                                .filter(([key]) => displayKeys.includes(key))
                                .map(([key, value]) => (

                                    <React.Fragment key={key}>
                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>
                                                <Typography variant="body1" sx={{ minWidth: '80px', fontSize: '12px' }}>
                                                    {camelToNormal(key)}:
                                                </Typography>
                                            </div>
                                            <div style={{ width: '60%', }}>
                                                <Typography variant="body1" sx={{ minWidth: '80px', fontSize: '12px', marginLeft: '10px' }}>
                                                    {String(value || '-')}
                                                </Typography>
                                            </div>
                                        </ListItem>
                                        {key === 'color' ? <></> : <Divider />}
                                    </React.Fragment>
                                ))}
                        </List>
                    </Typography>

                </Grid>

                {product?.description && (
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Accordion style={{ width: '100%' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                                Product Description
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="h5" component="h1" style={{ fontWeight: 500, display: 'flex', justifyContent: 'space-between' }}>
                                    <Button
                                        variant="outlined"
                                        startIcon={<Download />}
                                        style={{
                                            padding: '5px 20px',
                                            fontSize: 13,
                                            color: primaryColor,
                                            borderRadius: '5px',
                                            cursor: 'pointer',
                                            marginLeft: 'auto',
                                        }}
                                        onClick={handleDownloadExcel}
                                        download
                                    >
                                        Brochure
                                    </Button>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                                    {parse(product?.description || '')}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                                    Get it by Thu, 20 Aug
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Easy returns available | Cash on delivery available
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )}

                {(product?.amazon || product?.flipkart || product?.meesho || product?.snapdeal) && (
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Accordion style={{ width: '100%' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                                Visit Other Platforms
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sx={{ display: 'flex', gap: '10px' }}>
                                        {product?.amazon && (
                                            <Button
                                                variant="outlined"
                                                sx={{ ...styles.linkButton, ...styles.amazon }}
                                                href={product?.amazon}
                                                target="_blank"
                                            >
                                                Visit Amazon
                                            </Button>
                                        )}

                                        {product?.flipkart && (
                                            <Button
                                                variant="outlined"
                                                sx={{ ...styles.linkButton, ...styles.flipkart }}
                                                href={product?.flipkart}
                                                target="_blank"
                                            >
                                                Visit Flipkart
                                            </Button>
                                        )}
                                        {product?.meesho && (
                                            <Button
                                                variant="outlined"
                                                sx={{ ...styles.linkButton, ...styles.meesho }}
                                                href={product?.meesho}
                                                target="_blank"
                                            >
                                                Visit Meesho
                                            </Button>
                                        )}
                                        {product?.snapdeal && (
                                            <Button
                                                variant="outlined"
                                                sx={{ ...styles.linkButton, ...styles.snapdeal }}
                                                href={product?.snapdeal}
                                                target="_blank"
                                            >
                                                Visit Snapdeal
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default ProductComp;