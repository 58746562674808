import { Button, Grid } from "@mui/material";
import AdminVendorsTable from "../../../components/tables/AdminVendorsTable";
import { createButtonBg } from "../../../constant";
import { IoMdAdd } from "react-icons/io";
import AddVendorModal from "../../../components/AddVendorModal";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get_data, post_data } from "../../../api";
import EmptyPage from "../../../components/EmptyPage";
import AdminSubVendorsTable from "../../../components/tables/AdminSubVendorsTable";
import AddAdminSubVendorModals from "../../../components/models/AddAdminSubVendorModals";

export default function AdminSubVendors() {

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const getAllSubVendors = async () => {
        try {
            const data = await get_data(`sub-vendor/get-all-sub-vendors?pageNumber=${currentPage}`);
            console.log('AdminVendor', data?.data)
            if (data.status) {
                setLoading(false);
                setData(data?.data?.vendors);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalSubVendors);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllSubVendors(currentPage);
        }
    }, [currentPage])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);


    const itemsPerPage = 20;
    const count = data?.length;


    const tableHeader = [
        "S No.", "Unique Sub Vendor Id", "Sub Vendor Name", "Mobile Number", "Email", " State", "City", "Owner"
    ]

    const handleVandorSubmit = async (body, isUpdate) => {
        let result = await post_data(`vendor/create-vendor`)
    }

    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <div style={{ marginLeft: 'auto', gap: 10, display: 'flex', justifyContent: 'right', marginBottom: '1%' }}>
                    <AddAdminSubVendorModals AdminSubVendors={AdminSubVendors} title={'AdminSubVendors'}  isUpdate={false}/>
                </div>
                {
                    data?.length === 0 ?
                        <>
                            <EmptyPage tableHeader={tableHeader} />
                        </>
                        :
                        <>
                            <AdminSubVendorsTable
                                data={data}
                                refresh={refresh} 
                                setRefresh={setRefresh}
                                tableHeader={tableHeader}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                count={count}
                                totalData={totalData}
                                totalPages={totalPages}
                                loading={loading}
                                setLoading={setLoading}
                                getAllSubVendors={getAllSubVendors} />
                        </>}
            </Grid>

        </>
    )
}