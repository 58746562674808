import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, TextField, Chip, Select, MenuItem, InputLabel, FormControl, Grid, CircularProgress, useMediaQuery, Avatar } from '@mui/material';
import { formatDate, formatPrice, primaryColor } from '../../constant';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { serverURL, get_data, post_data } from '../../api';
import Swal from 'sweetalert2';
import InvoiceModal from './InvoiceModal';
import { styled } from '@mui/material/styles';
import RefundServicePayment from './RefundServicePayment';

const BookingDetailsModal = ({ open, setOpen, currentBooking, type }) => {
    const { user_data } = useSelector(state => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [invoiceModal, setInvoiceModal] = useState(false);
    const [currentInvoice, setCurrentInvoive] = useState({});

    const handleClose = () => {
        setOpen(false);
        setEmailError(false);
    };

    // const { primaryColor } = globalConstant();
    const isDesktop = useMediaQuery('(min-width: 768px)');

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            overflowY: 'auto',
            maxHeight: isDesktop ? '95%' : '80%',
            width: 600,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            textAlign: 'center',
            padding: !isDesktop ? 2 : ''
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        buttons: {
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            marginTop: '20px',
        },
        amenity: {
            fontSize: '12px',
            color: '#666',
            backgroundColor: '#fff',
            border: '1px solid black',
            borderRadius: '15px',
            padding: '2px 7px',
            marginRight: '5px',
            marginBottom: '5px',
            color: 'black'
        },
        amenities: {
            display: 'flex',
            flexWrap: 'wrap',
            marginRight: 'auto',
            justifyContent: 'right'
        },
        button: {
            padding: '10px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: primaryColor,
            borderRadius: '8px',
            cursor: 'pointer',
            border: '2px solid ' + primaryColor,
            marginTop: '3%',
            width: '30%',
            boxShadow: 'none'
        }
    };


    const statusStyles = {
        scheduled: {
            backgroundColor: '#ffe9a8',
            color: '#bf8f00',
        },
        completed: {
            backgroundColor: '#C8E6C9',
            color: '#388E3C',
        },
        cancelled: {
            backgroundColor: '#FFCDD2',
            color: '#D32F2F',
        }
    };

    const heading = {
        fontWeight: 600,
        fontSize: 22,
        marginBottom: '10px',
        textAlign: 'left',
        p: 3,
        borderBottom: '1px solid gainsboro'
    }

    const DataRow = ({ label, value }) => (
        <Box sx={{
            display: 'flex',
            padding: '2% 0',
        }}>
            <InvoiceModal currentInvoice={currentInvoice} currentIndex={1} open={invoiceModal} setOpen={setInvoiceModal} />
            <Typography variant="body1" style={{ flex: 2, textAlign: 'left', fontSize: 15 }}>{label}:</Typography>
            <Typography style={{ flex: 2, textAlign: 'right', fontSize: 15 }}>
                {
                    label === 'Receipt' ?
                        <>
                            <p style={{ margin: 0, color: primaryColor, fontWeight: 600, cursor: 'pointer' }}
                                onClick={() => {
                                    setInvoiceModal(true)
                                    setCurrentInvoive(currentBooking)
                                }}
                            >Download
                            </p>
                        </>
                        :
                        <>
                            <Typography style={{ marginLeft: 'auto', fontSize: 15, textAlign: 'right' }} variant="body1">{value}</Typography>
                        </>
                }
            </Typography>
        </Box>
    )
    console.log("bbbbbbbb", currentBooking)

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={styles.modalBox}>
                <Typography variant="h6" sx={heading}>
                    Booking Details
                </Typography>
                <div style={{ marginTop: 2, padding: '2% 3% 6% 3%' }}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} style={{ width: '100%' }}>
                            <DataRow label="Receipt" value={currentBooking?.orderUniqueId} />
                            <DataRow label="Booking ID" value={currentBooking?.orderUniqueId} />
                            <DataRow label="Amount" value={formatPrice(currentBooking?.amount)} />
                            <DataRow label="City" value={currentBooking?.shippingAddress?.city} />
                            <DataRow label="State" value={currentBooking?.shippingAddress?.state} />
                            <DataRow label="ZipCode" value={currentBooking?.shippingAddress?.zipCode} />
                            <DataRow label="Transaction Number" value={currentBooking?.transactionNumber} />
                            <DataRow label="Invoice Number" value={currentBooking?.invoiceNumber} />
                            <DataRow label="Order Status" value={currentBooking?.orderStatus} />
                            {
                                currentBooking?.productId?.map((product, i) =>
                                (<div
                                    key={i}
                                    style={{
                                        width: '90px',
                                        borderRadius: '8px',
                                        cursor: 'pointer',
                                        padding: '10px 5px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        alignItems: 'center',
                                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                        backgroundColor: '#fff',
                                        marginBottom: '10px',
                                    }}
                                >
                                    <Avatar
                                        src={`${serverURL}/uploads/products-images/${product?.images?.[0]}`}
                                        variant="rounded"
                                        style={{
                                            width: '100%', // Occupy the full width
                                            height: '70px', // Slightly smaller height
                                            borderRadius: '8px',
                                            border: '1px solid #ddd', // Lighter border
                                            objectFit: 'cover',
                                        }}
                                    />
                                    <div
                                        style={{
                                            fontSize: '0.9rem', // Reduced font size
                                            color: '#333', // Darker color for better contrast
                                            fontWeight: 500, // Made font weight bolder
                                            marginTop: '8px', // Reduced margin
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textAlign: 'center', // Center-aligned text
                                            maxWidth: '100%',
                                        }}
                                    >
                                        {product?.name}
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '1rem', // Slightly reduced size
                                            fontWeight: 600, // Increased font weight for price
                                            // color: '#e74c3c', // Accent color for price
                                            marginTop: '5px', // Slight margin above price
                                            textAlign: 'start',
                                            width: '100%'
                                        }}
                                    >
                                        {formatPrice(product?.price)} <span style={{fontSize:'12px'}}>{' x ' + currentBooking?.productInfo?.[product?._id]?.qty}</span>
                                    </div>
                                </div>
                                )
                                )}
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography sty variant='body1'>accessories:</Typography>
                                {
                                    currentBooking?.accessoryId.length > 0 ? <>{currentBooking?.accessoryId?.map((acces, i) =>
                                    (<div
                                        key={i}
                                        style={{
                                            width: '90px',
                                            borderRadius: '8px',
                                            cursor: 'pointer',
                                            padding: '10px 5px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            alignItems: 'center',
                                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                            backgroundColor: '#fff',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <Avatar
                                            src={`${serverURL}/uploads/accessories-images/${acces?.images?.[0]}`}
                                            variant="rounded"
                                            style={{
                                                width: '100%', // Occupy the full width
                                                height: '70px', // Slightly smaller height
                                                borderRadius: '8px',
                                                border: '1px solid #ddd', // Lighter border
                                                objectFit: 'cover',
                                            }}
                                        />
                                        <div
                                            style={{
                                                fontSize: '0.9rem', // Reduced font size
                                                color: '#333', // Darker color for better contrast
                                                fontWeight: 500, // Made font weight bolder
                                                marginTop: '8px', // Reduced margin
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textAlign: 'center', // Center-aligned text
                                                maxWidth: '100%',
                                            }}
                                        >
                                            {acces?.name}
                                        </div>
                                        <div
                                            style={{
                                                fontSize: '1rem', // Slightly reduced size
                                                fontWeight: 600, // Increased font weight for price
                                                // color: '#e74c3c', // Accent color for price
                                                marginTop: '5px', // Slight margin above price
                                                textAlign: 'start',
                                                width: '100%'
                                            }}
                                        >
                                            {formatPrice(acces?.price)}
                                        </div>
                                    </div>
                                    )
                                    )}</> : <></>}
                            </div>
                            <DataRow label="Created Date" value={formatDate(currentBooking?.createdAt)} />
                        </Grid>
                    </Grid>
                </div>
            </Box>
        </Modal >
    );
};

export default BookingDetailsModal;
