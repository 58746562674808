
import { Button, Grid } from "@mui/material";
import { createButtonBg } from "../../../constant";
import { IoMdAdd } from "react-icons/io";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api";
import EmptyPage from "../../../components/EmptyPage";
import SearchComponent from "../../../components/SearchComponent";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReturnsTransactionModal from "../../../components/models/ReturnsTransactionModal";
import { useDispatch, useSelector } from "react-redux";
import ClientsReturnsTable from "../../../components/tables/ClientsReturnsTable";
import { useNavigate } from "react-router-dom";

const rangePickerStyle = {
    display: 'flex',
    cursor: 'pointer',
    fontSize: 14,
    background: 'white',
    padding: '0% 0% 0% 0%',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    color: '#7E8299',
    cursor: 'pointer'
};

export default function ClientsReturn() {

    const page = { width: '100%', marginBottom: '2%' };
    const navigate = useNavigate()
    const { user_data } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [value, setValue] = useState('')
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(false)
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [returnsData, setReturnsData] = useState([])

    const itemsPerPage = 15;
    const count = data?.length;



    const tableHeader = [
        "S No.", "Vehicle Number", "Imei Number", "Sim Number", "Device UniqueId", "Uninstall ID", "Date"
    ]

    const getReturnData = async (page) => {
        try {
            setLoading(true);
            let data
            user_data?.isSubClient ?
                data = await get_data(`return/get-all-return-by-sub-client/${user_data?._id}?pageNumber=${page || currentPage}`) :
                data = await get_data(`return/get-all-return-by-client/${user_data?._id}?pageNumber=${page || currentPage}`);
            if (data.status) {
                setLoading(false);
                setData(data?.data?.returns);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalReturns);
                if (page) {
                    setCurrentPage(page);
                }
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            if (value.length > 0) {
                handleSearch(currentPage);
            } else {
                getReturnData(currentPage);
            }
        }
    }, [currentPage])


    const handleSearch = async (page) => {
        if (value.length > 0) {
            setLoading(true);
            let data
            user_data?.isSubClient ?
                data = await get_data(`return/search-return-for-sub-client/${value}?pageNumber=${page || 1}`) :
                data = await get_data(`return/search-return-for-client/${value}?pageNumber=${page || 1}`)
            if (data.status === true) {
                setData(data?.data?.returns)
                setTotalData(data?.data?.totalReturns)
                setTotalPages(data?.data?.totalPages)
                setCurrentPage(page || 1);
                setLoading(false);
            }
        } else {
            getReturnData(1);
        }
        setLoading(false);
    }


    const handleDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
        }
    };

    const fetchExportData = async () => {
        try {

            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();
            let response
            user_data?.isSubClient ?
                response = await post_data('return/get-returns-by-date-for-sub-client', {
                    from: fromDate,
                    to: toDate
                }) :
                response = await post_data('return/get-returns-by-date-for-client', {
                    from: fromDate,
                    to: toDate
                })

            if (response?.status === true) {
                setReturnsData(response?.data?.returns)
                setData(response?.data?.returns)
                setOpen(true)
                setFromDate(response?.data?.fromDate)
                setToDate(response?.data?.toDate)
            } else {

            }
        } catch (error) {
        }
    };


    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>

                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <div style={{ ...rangePickerStyle, border: 'none', }}>
                            <DatePicker
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd-MM-yyyy"
                                maxDate={new Date()}
                                customInput={
                                    <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                        {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                    </button>
                                }
                            />
                        </div>
                        <Button
                            startIcon={<IoMdAdd style={{ color: 'white' }} />}
                            onClick={fetchExportData}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Export
                        </Button>

                        <Button
                            onClick={() => navigate("/company/dashboard/returnform")}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Create
                        </Button>
                    </div>

                </div>
                {
                    data?.length === 0 ?
                        <>
                            <EmptyPage  tableHeader={tableHeader}/>
                        </>
                        :
                        <>
                            <ClientsReturnsTable data={data} tableHeader={tableHeader}
                                setCurrentPage={setCurrentPage}
                                count={count}
                                totalData={totalData}
                                totalPages={totalPages}
                                loading={loading}
                                setLoading={setLoading}
                                getReturnData={getReturnData}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                            />
                            <ReturnsTransactionModal
                                type={'client'}
                                open={open}
                                setOpen={setOpen}
                                fromDate={fromDate}
                                toDate={toDate}
                                data={returnsData}
                            />
                        </>
                }
            </Grid>
        </>
    )
}