import { Button, Grid } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import RecordsTable from "./RecordsTable";
import { baseURL, get_data, post_data } from "../../api";
import SearchComponent from "../SearchComponent";
import EmptyPage from "../EmptyPage";
import { createButtonBg, primaryColor, primaryColorHover } from "../../constant";
import AdminRecordModal from "../models/AdminRecordModal";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import RecordTransactionModal from "../models/RecordTransactionModal";
import * as XLSX from 'xlsx';
import toast from "react-hot-toast";

const rangePickerStyle = {
    display: 'flex',
    cursor: 'pointer',
    fontSize: 14,
    background: 'white',
    padding: '0% 0% 0% 0%',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    color: '#7E8299',
    cursor: 'pointer'
};

export default function Record() {
    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [refresh, setRefresh] = useState(false)
    const [value, setValue] = useState('')
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([]);
    const [recordData, setRecordData] = useState([])
    const [openTransaction, setOpenTransaction] = useState(false);
    const [fileName, setFileName] = useState('');
    const [excelData, setExcelData] = useState([]);
    const [excelLoading, setExcelLoading] = useState(false);

    const getAllRecords = async () => {
        try {
            const data = await get_data(`record/get-all-records?pageNumber=${currentPage}`);
            if (data.status === true) {
                setLoading(false);
                setData(data?.data?.records);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalRecords);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllRecords(currentPage);
        }
    }, [currentPage, refresh])


    const handleSearch = async () => {
        setLoading(true);
        if (value.length > 0) {
            const data = await get_data(`record/search-record/${value}?pageNumber=${currentPage}`)
            if (data.status === true) {
                setData(data?.data?.records);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalRecords);
            }
        } else {
            getAllRecords(currentPage);
        }
        setLoading(false);
    }

    const itemsPerPage = 15;
    const count = data?.length;


    const tableHeader = [
        "S No.", 'Company', "Vehicle No", 'Imei Number', 'Sim Number', 'Device Unique ID', 'Driver', "Installer", "Installed date", "Un Installed date", "Return Date", "Claim Date"
    ]

    const handleCreate = () => {
        setOpen(true)
    }


    const handleDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
        }
    };

    const fetchExportData = async (search) => {
        try {
            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();

            let response = await post_data('record/get-all-records-by-date', {
                from: fromDate,
                to: toDate
            })
            if (response?.status === true) {
                setRecordData(response?.data?.record);
                // setData(response?.data?.record);
                setOpenTransaction(true);
                setFromDate(response?.data?.fromDate);
                setToDate(response?.data?.toDate);
            } else {

            }
        } catch (error) {

        }
    };


    const downloadCSV = () => {
        const userAgent = window.navigator.userAgent;
        let fileType = '';
        let fileName = '';

        if (userAgent.includes('Mac')) {
            fileType = 'numbers';
            fileName = 'Record.numbers';
        } else if (userAgent.includes('Windows')) {
            fileType = 'xlsx';
            fileName = 'Record.xlsx';
        } else {
            fileType = 'xlsx';
            fileName = 'Record.xlsx';
        }

        const link = document.createElement('a');
        link.href = `${baseURL}/images/${fileName}`;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setFileName(file.name);
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);

            setExcelData(jsonData);
        };

        reader.readAsArrayBuffer(file);
    };

    const handleSubmitExcel = async () => {
        try {
            setExcelLoading(true);
            const result = await post_data("record/create-record-by-excel", excelData);
            if (result?.status === true) {
                toast.success("Records created Successfully");
                setExcelData([]);
                setFileName('');
                getAllRecords(currentPage);
            } else {
                toast.error(result?.response?.data?.message || "Something went wrong");
                getAllRecords(currentPage);
            }
            setExcelLoading(false);
        } catch (error) {
            setExcelLoading(false);
            toast.error(error?.response?.data?.message || "Something went wrong");
        }
    };



    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div>

                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        {
                            excelData.length === 0 &&
                            <>
                                <Button
                                    onClick={downloadCSV}
                                    variant='outlined'
                                    sx={{
                                        borderColor: primaryColor,
                                        color: primaryColor,
                                        textTransform: 'capitalize',
                                        borderRadius: 0,
                                        fontWeight: 400,
                                        boxShadow: 'none',
                                        '&:hover': {
                                            borderColor: primaryColor,
                                            boxShadow: 'none',
                                        },
                                        '&:focus': {
                                            outline: 'none',
                                            boxShadow: 'none',
                                            borderColor: primaryColor,
                                        }
                                    }}
                                >
                                    Download Excel
                                </Button>
                                <Button
                                    variant="contained"
                                    component="label"
                                    sx={{
                                        background: primaryColor,
                                        color: 'white',
                                        borderRadius: 0,
                                        fontWeight: 400,
                                        textTransform: 'capitalize',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            backgroundColor: primaryColorHover,
                                            boxShadow: 'none',
                                        },
                                        '&:focus': {
                                            outline: 'none',
                                            boxShadow: 'none',
                                            backgroundColor: primaryColor,
                                        },
                                    }}
                                >
                                    Upload Excel
                                    <input
                                        type="file"
                                        accept=".xlsx, .xls"
                                        hidden
                                        onChange={handleFileUpload}
                                    />
                                </Button>
                            </>
                        }
                        {fileName && <p>Uploaded File: {fileName}</p>}

                        {excelData.length > 0 && (
                            <Button
                                variant="contained"
                                sx={{
                                    background: primaryColor,
                                    color: 'white',
                                    borderRadius: 0,
                                    fontWeight: 400,
                                    textTransform: 'capitalize',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        backgroundColor: primaryColorHover,
                                        boxShadow: 'none',
                                    },
                                    '&:focus': {
                                        outline: 'none',
                                        boxShadow: 'none',
                                        backgroundColor: primaryColor,
                                    }
                                }}
                                disabled={excelLoading}
                                onClick={handleSubmitExcel}
                            >
                                {excelLoading ? 'Uploading...' : "Submit"}
                            </Button>
                        )}
                    </div>

                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <div style={{ ...rangePickerStyle, border: 'none', }}>
                            <DatePicker
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd-MM-yyyy"
                                maxDate={new Date()}
                                customInput={
                                    <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                        {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                    </button>
                                }
                            />
                        </div>
                        <Button
                            onClick={fetchExportData}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Export
                        </Button>

                        <Button
                            onClick={() => setOpen(true)}
                            startIcon={<IoMdAdd style={{ color: 'white' }} />}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Create
                        </Button>
                        <AdminRecordModal getAllRecords={getAllRecords} open={open} setOpen={setOpen} isUpdate={false} />
                    </div>

                </div>

                {
                    data?.length === 0 ?
                        <>
                            <EmptyPage tableHeader={tableHeader} />
                        </>
                        :
                        <>
                            <RecordsTable
                                data={data}
                                tableHeader={tableHeader}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                count={count}
                                totalData={totalData}
                                totalPages={totalPages}
                                loading={loading}
                                setLoading={setLoading}
                                getAllRecords={getAllRecords}
                                refresh={refresh} setRefresh={setRefresh}
                            />

                            <RecordTransactionModal
                                openTransaction={openTransaction}
                                setOpenTransaction={setOpenTransaction}
                                fromDate={fromDate}
                                toDate={toDate}
                                data={recordData}
                            />
                        </>
                }
            </Grid>

        </>
    )
}