import { TextField, Grid, Switch, FormControlLabel, Button, FormControl, InputLabel, Select, MenuItem, ListItemText, Autocomplete } from '@mui/material';
import { primaryColor } from '../constant';
import { useEffect, useState } from 'react';
import { get_data, post_data } from '../api';
import RequestsDetailsTable from './RequestDetailsTable';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import RequestDetails from './RequestDetails';
import toast, { Toaster } from 'react-hot-toast';
import RequestDetails2 from './RequestDetails2';

export default function ClaimForm() {
    const { isLoggedIn, user_data } = useSelector(state => state.user);
    const [requestId, setRequestId] = useState('');
    const [requestData, setRequestData] = useState('');
    const [claimReason, setClaimReason] = useState('')
    const [loader, setLoader] = useState(false);
    const [options, setOptions] = useState([]);
    const [regnNumber, setRegnNumber] = useState([]);

    const fetchInstalledGps = async () => {
        const data = await get_data(`inventory/get-all-installed-gps/${user_data?._id}`);
        if (data?.status === true) {
            setOptions(data?.data?.map((item) => item?.gpsDetail?.vehicleNumber) || []);
        }
    };

    useEffect(() => {
        fetchInstalledGps()
    }, []);

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const validateForm = () => {
        if (!regnNumber.length) {
            toast.error('At least one Registration Number is required')
            return false;
        }
        if (claimReason == '') {
            toast.error('Claim Reason is required')
            return false;
        }
        return true;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            let body

            body = {
                claimReason: claimReason,
                clientId: user_data?._id,
                vehicleNumbers: regnNumber
            }
            setLoader(true)
            let result = await post_data('claim/create-claim', body)
            if (result?.status === true) {
                toast.success('Claim request submitted')
            } else {
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }

            setLoader(false)
        };
    };


    const button = {
        borderColor: primaryColor,
        color: 'white',
        background: primaryColor,
        textTransform: 'capitalize',
        borderRadius: 0,
        fontWeight: 400,
        padding: '1% 3.5%',
        boxShadow: 'none',
        '&:hover': {
            borderColor: primaryColor,
            boxShadow: 'none',
        },
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
            borderColor: primaryColor,
        }
    }

    const fetchRequestData = async () => {
        setLoader(true)
        const result = await post_data('request/get-request-by-requestId', { requestId: requestId })

        setLoader(false)
        if (result?.status === true) {
            setRequestData(result?.data)
        } else {
            toast.error("Request Data Not Found !")
        }
    }

    return (
        <>
            <Grid container spacing={2} style={{ padding: '3% 7%' }}>
                <Grid item md={6} style={{ backgroundColor: '#fff' }}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            {/* Claim Form */}
                            <Grid item xs={12}>
                                <h2>Claim Form</h2>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        multiple
                                        options={options}
                                        value={regnNumber}
                                        onChange={(event, newValue) => setRegnNumber(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Vehicle Number"
                                            />
                                        )}
                                    />

                                    {/* <Select
                                        label="Select Vehicle Number"
                                        multiple
                                        value={regnNumber}
                                        onChange={(e) => setRegnNumber(e.target.value)}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {options.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                <ListItemText primary={option} />
                                            </MenuItem>
                                        ))}
                                    </Select> */}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    multiline
                                    rows={4}
                                    label="Claim Reason" name="claim_reason" type='text' fullWidth onChange={(e) => setClaimReason(e.target.value)} />
                            </Grid>
                            {/* Submit Button */}
                            <Grid item xs={12}>
                                <Button variant="contained" type="submit" style={button} onClick={handleSubmit} >
                                    {loader ? 'Loading...' : "Submit"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item md={6} style={{ backgroundColor: '#fff' }}>
                    {requestData && <RequestDetails2 detailsData={requestData} />}
                </Grid>

            </Grid>
        </>
    )
}