import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Autocomplete, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useState, useEffect } from "react";
import { createButtonBg, primaryColor } from '../../constant';
import { get_data, post_data } from '../../api';
import { Toaster, toast } from 'react-hot-toast';
import { Country, State, City } from 'country-state-city';
import { useDispatch, useSelector } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
    maxHeight: "80vh",
    overflow: 'auto'
};

export default function ServiceRequestModals({ setOpen, open, editData, isUpdate, getServiceRequestData }) {
    const { user_data } = useSelector(state => state.user);
    const dispatch = useDispatch();

    const [formData, setFormdata] = useState({
        vehicleNo: '',
        clientId: user_data._id,
        driverName: '',
        shift: '',
        driverAddress: {
            street: '',
            state: '',
            city: '',
            pincode: '',
        },
        simNumber: '',
        imeiNo: '',
        deviceUniqueId: '',
        remark: ''
    });
    const [errors, setErrors] = useState({});
    const [countryCode] = useState('IN');
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [vehicleList, setVehicleList] = useState([]);

    const fetchVehicleNumbers = async () => {
        try {
            setFetchLoading(true);
            const data = await get_data(`inventory/get-all-gps-for-service`);
            if (data?.status === true) {
                setVehicleList(data?.data);
            }
            setFetchLoading(false);
        } catch (error) {
            setFetchLoading(false);
        }
    }

    useEffect(() => {
        fetchVehicleNumbers()
    }, []);

    useEffect(() => {
        if (isUpdate && editData) {
            setFormdata({
                vehicleNo: editData?.vehicleNo,
                imeiNo: editData?.imeiNo,
                deviceUniqueId: editData?.deviceUniqueId,
                simNumber: editData?.simNumber,
                remark: editData?.remark,
                driverName: editData?.driverName,
                driverPhoneNo: editData?.driverPhoneNo,
                shift: editData?.shift,
                driverAddress: {
                    street: editData?.driverAddress?.street,
                    state: editData?.driverAddress?.state,
                    city: editData?.driverAddress?.city,
                    pincode: editData?.driverAddress?.pincode,
                }
            });
            // fetchDeviceDetail({ vehicleNo: editData?.vehicleNo })
        }
    }, [editData, isUpdate]);

    useEffect(() => {
        setStateList(State.getStatesOfCountry(countryCode));
    }, [countryCode]);

    useEffect(() => {
        if (formData?.driverAddress?.state) {
            setCityList(City.getCitiesOfState(countryCode, formData?.driverAddress?.state));
        }
    }, [formData?.driverAddress?.state, countryCode]);


    useEffect(() => {
        if (formData?.vehicleNo && !isUpdate) {
            fetchDeviceDetail(formData?.vehicleNo);
        }
    }, [formData?.vehicleNo]);

    const fetchDeviceDetail = async (vehicleNo) => {
        try {
            setFetchLoading(true);
            const data = await post_data(`service-request/get-device-detail`, { vehicleNo, clientId: user_data?._id });
            if (data?.status === true) {
                setFormdata((prev) => ({
                    ...prev,
                    driverName: data?.data?.gps[0]?.driverDetails?.fullName,
                    driverPhoneNo: data?.data?.gps[0]?.driverDetails?.contactNo,
                    shift: data?.data?.gps[0]?.driverDetails?.shift,
                    driverAddress: {
                        street: data?.data?.gps[0]?.driverDetails?.address?.street,
                        state: data?.data?.gps[0]?.driverDetails?.address?.state,
                        city: data?.data?.gps[0]?.driverDetails?.address?.city,
                        pincode: data?.data?.gps[0]?.driverDetails?.address?.zipCode,
                    },
                }));
            } else {
                setFormdata((prev) => ({ ...prev, imeiNo: '', deviceUniqueId: '', simNumber: '' }));
            }
            setFetchLoading(false);
        } catch (error) {
            console.log(error);
        }
    };
    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validation = () => {
        let error = false;
        if (!formData.vehicleNo) {
            error = true;
            handleError('Please input Vehicle No...', 'vehicleNo');
        } if (!formData.imeiNo) {
            error = true;
            handleError('Please input IMEI No...', 'imeiNo');
        }
        return error;
    };

    const handleClose = () => {
        setFormdata({});
        setOpen(false);
    };

    const handleSubmit = async () => {
        const hasError = validation();
        if (!hasError) {
            try {
                const data = isUpdate
                    ? await post_data(`service-request/update-service-request/${editData._id}`, { ...formData })
                    : await post_data(`service-request/create-service-request`, { ...formData });
                // console.log('DATA', data)
                setOpen(false);
                if (data?.status === true) {
                    setFormdata('');
                    getServiceRequestData();
                    toast.success(data.message || 'Operation successful!');
                } else {
                    toast.error('Something went wrong!');
                }
            } catch (error) {
                toast.error('An error occurred. Please try again.');
            }
        }
    };

    const handleStateChange = (event, newValue) => {
        if (newValue) {
            const stateCode = newValue.isoCode;
            const fetchedCities = City.getCitiesOfState(countryCode, stateCode);
            setCityList(fetchedCities);
            setFormdata((prev) => ({
                ...prev,
                driverAddress: { ...prev.driverAddress, state: stateCode, city: '' }
            }));
        }
    };

    const handleCityChange = (event, newValue) => {
        if (newValue) {
            setFormdata((prev) => ({
                ...prev,
                driverAddress: { ...prev.driverAddress, city: newValue.name }
            }));
        }
    };
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            {isUpdate ? 'Edit Service Request' : 'Create Service Request'}
                        </Typography>
                        <Grid container spacing={2} style={{ marginTop: 10 }}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={vehicleList}
                                    value={vehicleList.find(s => s?.gpsDetail?.vehicleNumber == formData?.vehicleNo || '') || null}
                                    onChange={(event, newValue) => {
                                        setFormdata({ ...formData, vehicleNo: newValue?.gpsDetail?.vehicleNumber, imeiNo: newValue?.gpsDetail?.imei, deviceUniqueId: newValue?.gpsDetail?.deviceUniqueId, simNumber: newValue?.gpsDetail?.simNumber });
                                    }}
                                    getOptionLabel={(option) => option?.gpsDetail?.vehicleNumber || ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Vehicle Number"
                                            fullWidth
                                        />
                                    )}
                                />

                                {/* <TextField
                                    error={Boolean(errors.vehicleNo)}
                                    helperText={errors.vehicleNo}
                                    onFocus={() => handleError('', 'vehicleNo')}
                                    value={formData?.vehicleNo}
                                    label="Vehicle no."
                                    onChange={(e) => setFormdata({ ...formData, vehicleNo: e.target.value })}
                                    fullWidth
                                />
                                {fetchLoading && <CircularProgress size={20} />} */}
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.imeiNo)}
                                    helperText={errors.imeiNo}
                                    onFocus={() => handleError('', 'imeiNo')}
                                    value={formData?.imeiNo}
                                    disabled
                                    label="IMEI No"
                                    onChange={(e) => setFormdata({ ...formData, imeiNo: e.target.value })}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.simNumber)}
                                    helperText={errors.simNumber}
                                    onFocus={() => handleError('', 'simNumber')}
                                    disabled
                                    value={formData?.simNumber}
                                    label="Sim Number"
                                    onChange={(e) => setFormdata({ ...formData, simNumber: e.target.value })}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.deviceUniqueId)}
                                    helperText={errors.deviceUniqueId}
                                    onFocus={() => handleError('', 'deviceUniqueId')}
                                    value={formData?.deviceUniqueId}
                                    label="Device Unique ID"
                                    disabled
                                    onChange={(e) => setFormdata({ ...formData, deviceUniqueId: e.target.value })}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.remark)}
                                    helperText={errors.remark}
                                    onFocus={() => handleError('', 'remark')}
                                    multiline
                                    rows={4}
                                    value={formData?.remark}
                                    label="remark"
                                    onChange={(e) => setFormdata({ ...formData, remark: e.target.value })}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.driverName)}
                                    helperText={errors.driverName}
                                    onFocus={() => handleError('', 'driverName')}
                                    value={formData.driverName}
                                    label="Driver Name"
                                    onChange={(e) => setFormdata({ ...formData, driverName: e.target.value })}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.driverPhoneNo)}
                                    helperText={errors.driverPhoneNo}
                                    onFocus={() => handleError('', 'driverPhoneNo')}
                                    value={formData?.driverPhoneNo}
                                    label="Driver Phone No"
                                    onChange={(e) => setFormdata({ ...formData, driverPhoneNo: e.target.value })}
                                    fullWidth
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Shift</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={formData?.shift}
                                        label="Shift"
                                        onChange={(e) => setFormdata({ ...formData, shift: e.target.value })}
                                    >
                                        <MenuItem value="Day">Day</MenuItem>
                                        <MenuItem value="Night">Night</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.street)}
                                    helperText={errors.street}
                                    onFocus={() => handleError('', 'street')}
                                    value={formData?.driverAddress?.street}
                                    label="Street"
                                    onChange={(e) => setFormdata({ ...formData, driverAddress: { ...formData.driverAddress, street: e.target.value } })}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    style={{ marginBottom: 10 }}
                                    id="state-select"
                                    fullWidth
                                    options={stateList}
                                    value={stateList.find(s => s.isoCode === formData?.driverAddress?.state) || null}
                                    onChange={handleStateChange}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} value={option.isoCode} key={option.isoCode}>
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            error={Boolean(errors.state)}
                                            helperText={errors.state}
                                            onFocus={() => handleError('', 'state')}
                                            label="Select State" fullWidth />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Autocomplete
                                    id="city-select"
                                    fullWidth
                                    options={cityList}
                                    value={cityList.find(c => c.name === formData?.driverAddress?.city) || null}
                                    onChange={handleCityChange}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} value={option.name} key={option.name}>
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            error={Boolean(errors.city)}
                                            helperText={errors.city}
                                            onFocus={() => handleError('', 'city')}
                                            {...params} label="Select City" fullWidth />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.pincode)}
                                    helperText={errors.pincode}
                                    onFocus={() => handleError('', 'pincode')}
                                    value={formData?.driverAddress?.pincode}
                                    label="Zip Code"
                                    onChange={(e) => setFormdata({ ...formData, driverAddress: { ...formData.driverAddress, pincode: e.target.value } })}
                                    fullWidth
                                />
                            </Grid>



                            <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        textTransform: 'capitalize',
                                        color: primaryColor,
                                        border: `1px solid ${primaryColor}`,
                                        borderRadius: 0
                                    }}
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{
                                        textTransform: 'capitalize',
                                        color: '#fff',
                                        background: primaryColor,
                                        borderRadius: 0
                                    }}
                                    onClick={handleSubmit}
                                    fullWidth
                                >
                                    {isUpdate ? 'Edit' : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
            {/* <Toaster /> */}
        </div>
    );
}
