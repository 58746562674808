import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Avatar,
    Button,
    Typography,
    Menu,
    MenuItem,
    IconButton,
    Pagination,
    CircularProgress,
    Switch,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TableLoader from './TableLoader';
import AddVendorModal from '../AddVendorModal';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { formatPrice, primaryColor } from '../../constant';
import { post_data } from '../../api';
import VendorDetailModal from '../VendorDetailModal';
import AdminChangPasswordModals from "./AdminChangPasswordModals";
import VendorDocsModal from '../VendorDocsModal';
import UpdateBilling from '../models/UpdateBilling';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
        '& .MuiCheckbox-root': {
            color: '#a3a3a3',
        },
    },
    avatarGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    avatarName: {
        marginLeft: '8px',
        fontSize: 14
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const BillingTable = ({ data, tableHeader, setLoading, totalData, loading, currentPage, setCurrentPage, itemsPerPage, totalPages, handleVendorSubmit, refresh, setRefresh, getAllBilling }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [updateData, setUpdateData] = useState('');
    const [rowsData, setRowsData] = useState(data || []);
    const [vendorUpdateOpen, setVendorUpdateOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [passwordChangOpen, setPasswordChangOpen] = useState(false);
    const [docModalOpen, setDocModalOpen] = useState(false)
    const [vendorData, setVendorData] = useState({});
    const [showAll, setShowAll] = useState([]);

    useEffect(() => { setRowsData(data) }, [data])
    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);

    const handleDetails = (row) => {
        setOpen(true);
        setUpdateData(row);
        setCurrentRow(null);
        setAnchorEl(null);
    }

    const [switchLoaders, setSwitchLoaders] = useState({});

    const handleSwitch = async (item) => {
        setSwitchLoaders(prev => ({ ...prev, [item?._id]: true }));
        try {
            let payload = { isActive: !item?.isActive }
            const data = await post_data(`vendor/update-vendor/${item?._id}`, payload)
            if (data.status) {
                toast.success("Vendor updated Successfully");
                setRefresh(!refresh)
                getAllBilling();
            }
            else {
                toast.error("Something Went Wrong");
            }
        } catch (error) {
        }
        setSwitchLoaders(prev => ({ ...prev, [item?._id]: false }));
    };


    const GetVehicles = ({ row, id }) => {
        const vehicles = row?.slice(0, 5);

        let res = vehicles?.map((item) => <div>{`${item},`}</div>) || '-';

        return (
            <>
                {res}
                {row?.length > 5 && <small style={{ color: primaryColor, cursor: 'pointer' }} onClick={() => setShowAll([...showAll, id])}>More</small>}
            </>
        )
    }

    const GetAllVehicles = ({ row, id }) => {
        return (
            <>
                {row?.map((item) => <div>{`${item},`}</div>) || '-'}
                <small style={{ color: primaryColor, cursor: 'pointer' }} onClick={() => setShowAll(showAll.filter(item => item !== id))}>Less</small>
            </>
        )
    }


    const GetDevices = ({ row, id }) => {
        const devices = row?.slice(0, 5);

        let res = devices?.map((item) => <div>{`${item},`}</div>) || '-';

        return (
            <>
                {res}
                {row?.length > 5 && <small style={{ color: primaryColor, cursor: 'pointer' }} onClick={() => setShowAll([...showAll, id])}>More</small>}
            </>
        )
    }

    const GetAllDevices = ({ row, id }) => {
        return (
            <>
                {row?.map((item) => <div>{`${item},`}</div>) || '-'}
                <small style={{ color: primaryColor, cursor: 'pointer' }} onClick={() => setShowAll(showAll.filter(item => item !== id))}>Less</small>
            </>
        )
    }

    return (
        <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
            <Table className={classes.root}>
                <TableHead>
                    <TableRow>
                        {tableHeader?.map((header, index) => (
                            <TableCell key={index} style={{ borderRight: '1px solid gainsboro', padding: '16px 10px' }}>
                                {header}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                {loading ? (
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={tableHeader.length}>
                                <TableLoader />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        {rowsData?.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center', width: '7%' }}>
                                    {startEntry + index}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '5%' }}>
                                    {row?.clientId?.companyName || '-'}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>
                                    {row?.clientId?.email || '-'}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '13%' }}>
                                    {row?.clientId?.contactNo || '-'}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '10%' }}>
                                    {row?.totalGps || '-'}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '10%' }}>
                                    {formatPrice(row?.amount) || '-'}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>
                                    {showAll.includes(row?._id) ? <GetAllVehicles row={row?.vehicleNumbers} id={row?._id} /> : <GetVehicles row={row?.vehicleNumbers} id={row?._id} />}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>
                                    {/* {row?.deviceUniqueIds.map((item) => <div>{`${item},`}</div>) || '-'} */}
                                    {showAll.includes(row?._id) ? <GetAllDevices row={row?.deviceUniqueIds} id={row?._id} /> : <GetDevices row={row?.deviceUniqueIds} id={row?._id} />}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                    <IconButton
                                        className={classes.moreIcon}
                                        onClick={(event) => handleMenuOpen(event, index)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>

                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl) && currentRow === index}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={() => {
                                            handleDetails(row);
                                        }}>
                                            Update
                                        </MenuItem>
                                    </Menu>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                )}


            </Table>

            <UpdateBilling open={open} setOpen={setOpen} updateData={updateData} getAllBilling={getAllBilling} />

            {rowsData?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px solid gainsboro",
                        padding: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography style={{ fontWeight: 500, fontSize: 15, color: "black" }}>
                        {`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}
                    </Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
        </TableContainer>
    );
};

export default BillingTable;
