import { Box, Card, CardContent, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, CircularProgress, TextField, Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../api";
import { Toaster, toast } from 'react-hot-toast';
import { primaryColor } from "../../constant";

const DefaultNetwork = () => {

    const [network, setNetwork] = useState('');
    const [relay, setRelay] = useState('');
    const [insurance, setInsurance] = useState('');
    const [simCard, setSimCard] = useState('');
    const [server, setServer] = useState('');
    const [loading, setLoading] = useState(true);
    const [updatedId, setUpdatedId] = useState(null);
    const [globalId, setGlobalId] = useState(null);

    const fetchNetwork = async () => {
        const result = await get_data('network/get-network');

        const global = await get_data('global/get-global-prices');

        if (result.status === true) {
            setNetwork(result?.data[0]?.name)
            setUpdatedId(result?.data[0]?._id)
        }
        console.log("global", global)

        if (global.status === true) {
            setRelay(global?.data?.relay);
            setInsurance(global?.data?.insurance);
            setSimCard(global?.data?.simCard);
            setServer(global?.data?.server);
            setGlobalId(global?.data?._id);
        }
        setLoading(false)
    }

    console.log("globalId", globalId);

    const handleChange = async (event) => {
        setLoading(true)

        const result = await post_data(`network/set-network/${updatedId}`, { name: event.target.value })

        if (result.status) {
            setNetwork(result?.data?.name)
            setUpdatedId(result?.data?._id)
            toast.success("Network Updated")
        } else {
            toast.error("Something went wrong")
        }
        setLoading(false)

    };

    useEffect(() => {
        fetchNetwork();
    }, []);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        )
    }

    const handleUpdate = async () => {
        setLoading(true)

        if (!globalId) {
            const result = await post_data(`global/create-global-prices`, { relay, insurance, simCard, server })
            if (result.status === true) {
                toast.success("Prices Updated")
            } else {
                toast.error("Something went wrong")
            }
            setLoading(false)
        } else {
            const result = await post_data(`global/set-global-prices/${globalId}`, { relay, insurance, simCard, server })

            if (result.status === true) {
                toast.success("Prices Updated")
            } else {
                toast.error("Something went wrong")
            }
            setLoading(false)
        }
    }

    return (
        <Box>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <Card>
                <CardContent>
                    <FormControl>
                        <FormLabel>Default Network</FormLabel>
                        <RadioGroup
                            value={network}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="airtel" control={<Radio />} label="Airtel" />
                            <FormControlLabel value="jio" control={<Radio />} label="Jio" />
                            <FormControlLabel value="vi" control={<Radio />} label="Vi" />
                            <FormControlLabel value="bsnl" control={<Radio />} label="Bsnl" />
                        </RadioGroup>
                    </FormControl>
                </CardContent>
            </Card>


            <Card sx={{ mt: 2 }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Relay Price"
                                value={relay}
                                onChange={(e) => setRelay(e.target.value)}
                                fullWidth
                            />
                        </Grid>


                        <Grid item xs={6}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Insurance Price"
                                value={insurance}
                                onChange={(e) => setInsurance(e.target.value)}
                                fullWidth
                            />
                        </Grid>


                        <Grid item xs={6}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Sim Card Price"
                                value={simCard}
                                onChange={(e) => setSimCard(e.target.value)}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Server Price"
                                value={server}
                                onChange={(e) => setServer(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <div>
                        <Button
                            style={{
                                backgroundColor: primaryColor,
                                color: '#ffff',
                                cursor: 'pointer',
                                boxShadow: 'none',
                                marginRight: 10,
                                marginTop: 10
                            }}
                            onClick={handleUpdate}
                        >
                            Update
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </Box>
    )
}

export default DefaultNetwork;