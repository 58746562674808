"use client";

import React, { useEffect, useState } from 'react';
import { Grid, TextField, Button, MenuItem, IconButton, useTheme, useMediaQuery, Typography, Autocomplete, Box } from '@mui/material';
import { IoChatbubblesOutline, IoCallOutline, IoLocationOutline } from 'react-icons/io5';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Country, State, City } from 'country-state-city';
import { post_data } from '../api';
import { primaryColor } from '../constant';

const ContactForm = () => {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({ name: '', number: '', email: '', country: '', state: '', city: '', address: '', zipCode: '', role: '', message: '' });
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);


    const theme = useTheme();
    const matches1 = useMediaQuery(theme.breakpoints.down('md'));
    const matches2 = useMediaQuery(theme.breakpoints.down(900));
    const matches3 = useMediaQuery(theme.breakpoints.down(500));

    const iconStyle = {
        border: '1px solid gainsboro',
        borderRadius: '50%',
        padding: '5px',
        color: primaryColor,
    };


    useEffect(function () {
        setCountryList(Country.getAllCountries())
    }, [])


    const leftGridItem = (icon, heading, content, detail) => (
        <div style={{ display: 'flex', marginBottom: '20px' }}>
            <IconButton style={{ ...iconStyle, alignSelf: 'flex-start' }}>
                {icon}
            </IconButton>
            <div style={{ marginLeft: '15px', width: '100%' }}>
                <h3 style={{ margin: 0, fontWeight: 500 }}>{heading}</h3>
                <p style={{ margin: '5px 0' }}>{content}</p>
                <p style={{ fontWeight: 600, margin: 0, width: '80%', fontSize: 15 }}>{detail}</p>
            </div>
        </div>
    );

    const handleError = (message, label) => {
        setErrors((prev) => ({ ...prev, [label]: message }))
    }

    const handleSubmit = async () => {
        var error = false

        if (formData.name.length == 0) {
            handleError("Pls Enter Name....", 'name')
            error = true
        }

        if (formData.number.length == 0) {
            handleError("Pls Enter number....", 'number')
            error = true
        }
        if (formData.email.length == 0) {
            handleError("Pls Enter email....", 'email')
            error = true
        }
        if (formData.country.length == 0) {
            handleError("Pls Enter country....", 'country')
            error = true
        }
        if (formData.state.length == 0) {
            handleError("Pls Enter state....", 'state')
            error = true
        }
        if (formData.city.length == 0) {
            handleError("Pls Enter city....", 'city')
            error = true
        }
        if (formData.address.length == 0) {
            handleError("Pls Enter address....", 'address')
            error = true
        }
        if (formData.zipCode.length == 0) {
            handleError("Pls Enter zipCode....", 'zipCode')
            error = true
        }
        if (formData.role.length == 0) {
            handleError("Pls Enter role....", 'role')
            error = true
        }
        if (formData.message.length == 0) {
            handleError("Pls Enter message....", 'message')
            error = true
        }

        if (error == false) {
            setLoading(true)

            var body = {
                name: formData.name, number: formData.number, email: formData.email,
                country: formData.country, state: formData.state, city: formData.city,
                address: formData.address, zipCode: formData.zipCode, role: formData.role,
                message: formData.message,
            }

            let result = await post_data(`contact/send-contact-email`, body)
            if (result.status == true) {
                setLoading(false)
                toast.success('Message submitted successfully!');
            } else {
                setLoading(false)
                toast.warn("Something Went Wrong!")
            }
        }

    }


    const handleCountryChange = (event, newValue) => {
        if (newValue) {
            const countryCode = newValue.isoCode;
            const fetchedStates = State.getStatesOfCountry(countryCode);
            setStateList(fetchedStates);
            setCityList([]);
            setFormData(prev => ({ ...prev, country: countryCode, state: '', city: '' }));
        }
    };

    const handleStateChange = (event, newValue) => {
        if (newValue) {
            const stateCode = newValue.isoCode;
            const countryCode = formData.country;
            const fetchedCities = City.getCitiesOfState(countryCode, stateCode);
            setCityList(fetchedCities);
            setFormData(prev => ({ ...prev, state: stateCode, city: '' }));
        }
    };

    const handleCityChange = (event, newValue) => {
        if (newValue) {
            setFormData(prev => ({ ...prev, city: newValue.name }));
        }
    };



    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '4%' }}>
            <div style={{ width: matches3 ? '100%' : '80%', height: 'auto', borderRadius: '15px', marginTop: '2%', padding: '20px', }}>
                <Grid container spacing={0}>
                    {/* Left Section */}
                    <Grid item md={5} style={{ marginBottom: matches2 ? '20px' : '' }}>
                        {leftGridItem(<IoChatbubblesOutline size={25} />, 'Chat to us', 'Our friendly team is here to help.', 'support@oredogps.com')}
                        {leftGridItem(<IoLocationOutline size={25} />, 'Visit us', 'Come say hello at our office HQ.', '6-67, Yerrakunta, Chandrayangutta, Hyderabad, Telangana 500005')}
                        {leftGridItem(<IoCallOutline size={25} />, 'Call us', 'Directly call us now.', '+91 8008379717')}
                        <div style={{ display: 'flex', marginTop: '10%', gap: '15px' }}>
                            <FaFacebookF style={iconStyle} />
                            <FaTwitter style={iconStyle} />
                            <FaYoutube style={iconStyle} />
                        </div>
                    </Grid>

                    {/* Right Section */}
                    <Grid item md={7} xs={12}>
                        <div style={{ backgroundColor: '#f2f2f2', padding: '30px', borderRadius: '10px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h2 style={{ fontWeight: 500 }}>GET Started with us</h2>
                                    <div style={{ marginTop: '3%', marginBottom: '2%' }}>Fill the details below </div>
                                </Grid>
                                <Grid item md={4} sm={4} xs={12}>
                                    <TextField
                                        label="Name" variant="outlined"
                                        fullWidth
                                        name='name'
                                        value={formData.name}
                                        error={errors?.name}
                                        required
                                        // onChange={(e) => { setName(e.target.value) }}
                                        onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))}
                                        helperText={errors?.name}
                                        onFocus={() => handleError('', 'name')}
                                        style={{ maxWidth: '400px' }} />
                                </Grid>
                                <Grid item md={4} sm={4} xs={12}>
                                    <TextField label="Number" variant="outlined" fullWidth
                                        value={formData.number || ""}
                                        error={errors?.number}
                                        required
                                        helperText={errors?.number}
                                        onFocus={() => handleError('', 'number')}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) {
                                                // Regular expression to match exactly 10 digits
                                                const regex = /^\d{10}$/;
                                                if (value.length === 0 || regex.test(value)) {
                                                    handleError('', 'number')
                                                } else {
                                                    handleError('Mobile number must be exactly 10 digits.', 'number')
                                                }

                                            } else {
                                                handleError('Only digits are allowed.', 'number')
                                            }

                                            setFormData((prev) => ({ ...prev, number: value }))
                                        }}

                                    />
                                </Grid>
                                <Grid item md={4} sm={4} xs={12}>
                                    <TextField label="Email" variant="outlined" fullWidth
                                        value={formData.email}
                                        error={errors?.email}
                                        required
                                        onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))}
                                        helperText={errors?.email}
                                        onFocus={() => handleError('', 'email')}
                                        style={{ maxWidth: '500px' }} />
                                </Grid>
                                <Grid item md={4} sm={4} xs={12}>

                                    <Autocomplete
                                        id="country-select"
                                        options={countryList}
                                        value={countryList.find(c => c.isoCode === formData.country) || null}
                                        onChange={handleCountryChange}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                {...props}
                                                value={option.isoCode}
                                                key={option.isoCode}
                                            >
                                                {option.name}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Country"
                                                fullWidth
                                            />
                                        )}
                                    />

                                    {/* Add more countries as needed */}

                                </Grid>
                                <Grid item md={4} sm={4} xs={12}>

                                    <Autocomplete
                                        id="state-select"
                                        options={stateList}
                                        value={stateList.find(s => s.isoCode === formData.state) || null}
                                        onChange={handleStateChange}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                {...props}
                                                value={option.isoCode}
                                                key={option.isoCode}
                                            >
                                                {option.name}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select State"
                                                fullWidth
                                            />
                                        )}
                                    />


                                </Grid>
                                <Grid item md={4} sm={4} xs={12}>
                                    {/* <TextField
                                        label=" Select City"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Select City"
                                        name="city"
                                        value={city}
                                        error={errors?.city}
                                        select
                                        required
                                        onChange={(e) => { setCity(e.target.value) }}
                                        helperText={errors?.city}
                                        onFocus={() => handleError('', 'city')}
                                    >
                                        {cityList?.map((item, i) => {
                                            return <MenuItem key={i} value={item?.name} >{item?.name}</MenuItem>
                                        })}
                                    </TextField> */}


                                    <Autocomplete
                                        id="city-select"
                                        options={cityList}
                                        value={cityList.find(c => c.name === formData.city) || null}
                                        onChange={handleCityChange}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                {...props}
                                                value={option.name}
                                                key={option.name}
                                            >
                                                {option.name}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select City"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label="Address" variant="outlined" fullWidth rows={2} multiline
                                        value={formData.address}
                                        error={errors?.address}
                                        required
                                        onChange={(e) => setFormData((prev) => ({ ...prev, address: e.target.value }))}
                                        helperText={errors?.address}
                                        onFocus={() => handleError('', 'address')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField label="Zipcode" variant="outlined" fullWidth
                                        value={formData.zipCode}
                                        error={errors?.zipCode}
                                        required
                                        onChange={(e) => setFormData((prev) => ({ ...prev, zipCode: e.target.value }))}
                                        helperText={errors?.zipCode}
                                        onFocus={() => handleError('', 'zipCode')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField select label="Role" variant="outlined" fullWidth
                                        value={formData.role}
                                        error={errors?.role}
                                        required
                                        onChange={(e) => setFormData((prev) => ({ ...prev, role: e.target.value }))}
                                        helperText={errors?.role}
                                        onFocus={() => handleError('', 'role')}
                                    >
                                        <MenuItem value="installer">Installer</MenuItem>
                                        <MenuItem value="technician">Technician</MenuItem>
                                        <MenuItem value="reseller">Reseller</MenuItem>
                                        <MenuItem value="user">User</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Message"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={2}
                                        value={formData.message}
                                        error={errors?.message}
                                        required
                                        onChange={(e) => setFormData((prev) => ({ ...prev, message: e.target.value }))}
                                        helperText={errors?.message}
                                        onFocus={() => handleError('', 'message')}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1%' }}>
                                    <Button
                                        variant="contained"
                                        disabled={loading}
                                        fullWidth
                                        onClick={handleSubmit}
                                        style={{
                                            backgroundColor: primaryColor,
                                            borderRadius: '50px',
                                            color: 'white',
                                            padding: '15px 10px',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                boxShadow: 'none',
                                            }
                                        }}
                                    >
                                        {loading ? 'Submit...' : 'Submit'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default ContactForm;
