import React, { useRef, useEffect } from "react";
import { TextField, Button } from "@mui/material";
import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { post_data } from "../api";
import { login } from "../redux/slices/user-slice"
import { primaryColor } from "../constant";
import { Toaster, toast } from 'react-hot-toast';

export default function OtpComponent({
    formData,
    isClients,
    newNumber,
    type,
    mobileNumber,
    email,
    newEmail,
    setOpen,
    handleResend,
    password,
    fullName,
    phone,
    gst, images, images2, images3, images4, images5,
    state, city, country,
    gps
}) {
    console.log('iscleints', isClients)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const [loading2, setLoading2] = useState(false);
    const otpInputRefs = useRef([]);
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const theme = useTheme();
    const matches_sm = useMediaQuery(theme.breakpoints.down("sm"));
    const matches_md = useMediaQuery(theme.breakpoints.down("md"));
    const { user_data } = useSelector(state => state.user);

    const [timer, setTimer] = useState(59);
    const [canResend, setCanResend] = useState(false);
    useEffect(() => {
        let interval;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        } else {
            setCanResend(true);
        }
        return () => clearInterval(interval);
    }, [timer]);

    const handleFocus = (index) => {
        setFocusedIndex(index);
    };

    const otpBox = {
        width: matches_md ? 30 : 30,
        height: matches_md ? 40 : 40,
        background: "transparent",
        padding: "2.5% 4%",
        borderRadius: 0,
        display: "flex",
        alignItems: "center",
    };

    const handleOtpChange = (index, value) => {
        let arr = value !== "" ? value.split("") : "";
        let currentValue = "";
        if (arr !== "") {
            currentValue = arr[arr.length - 1];
        }

        const newOtp = [...otp];
        newOtp[index] = currentValue;
        setOtp(newOtp);

        if (currentValue !== "" && index < otpInputRefs.current.length - 1) {
            otpInputRefs.current[index + 1].focus();
        }

        if (currentValue === "" && index > 0) {
            otpInputRefs.current[index - 1].focus();
        }
    };

    const handleSubmit = async () => {

        setLoading2(true);

        if (type === 'client_login' || type === 'client_signup') {
            // let data = { email, password, companyName: fullName, contactNo: phone, gps, doc1: images, doc2: images2, doc3: images3, doc4: images4, doc5: images5, address: JSON.stringify({ state, city }), otp: otp.join("") };
            let formData = new FormData();
            formData.append('email', email);
            formData.append('companyName', fullName);
            formData.append('password', password);
            formData.append('contactNo', phone);
            formData.append('gst', gst);
            formData.append('address', JSON.stringify({ state, city, country }));
            formData.append('otp', otp.join(""));
            formData.append('doc1', images.bytes);
            formData.append('doc2', images2.bytes);
            formData.append('doc3', images3.bytes);
            formData.append('doc4', images4.bytes);
            formData.append('doc5', images5.bytes);
            // Object.keys(data).forEach((key) => {
            // if (key === 'images' ) {
            //     // Append each image file
            //     images.forEach((image) => {
            //         formData.append('images', image);
            //     });
            // } else {
            // formData.append(key, data[key]);
            // }
            // });

            // let payload = { email, password, companyName: fullName, contactNo: phone, gps, images, address: { state, city }, otp: otp.join("") };
            const response = await post_data("client/verify-otp-for-client-signup", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response?.status === true) {
                setTimeout(() => {
                    setLoading2(false)
                    setOpen(false);
                    dispatch(login(response?.data?.user));
                    localStorage.setItem("authToken", response?.data?.token);
                    navigate('/company/dashboard');
                    window.scrollTo(0, 0)
                    toast.success('Login Successfull!');
                }, 500);
            } else {
                toast.error('Wrong Otp!');
            }
            setLoading2(false);
        }
        else if (type === 'verify-email') {
            let payload = { email: email, otp: otp.join("") };  // customer verify email
            const response = await post_data("customer/verify-otp-for-email", payload);
            if (response?.status) {
                setTimeout(() => {
                    setLoading2(false)
                    setOpen(false);
                    dispatch(login(response?.data));
                    navigate('/dashboard');
                    window.scrollTo(0, 0)
                }, 1000);
            } else {
                toast.error('Wrong Otp!');
            }
            setLoading2(false);
        } else if (type === 'vendor_signup') {
            // let payload = { email: email, otp: otp.join(""), password, name: fullName };
            let formData = new FormData();
            formData.append('email', email);
            formData.append('name', fullName);
            formData.append('password', password);
            formData.append('contactNo', phone);
            formData.append('gst', gst);
            formData.append('address', JSON.stringify({ state, city }));
            formData.append('otp', otp.join(""));
            formData.append('doc1', images.bytes);
            formData.append('doc2', images2.bytes);
            formData.append('doc3', images3.bytes);
            formData.append('doc4', images4.bytes);
            formData.append('doc5', images5.bytes);

            // let data = { email, password, name: fullName, phone, gst, images, address: JSON.stringify({ state, city }), otp: otp.join("") };
            // let formData = new FormData();
            // Object.keys(data).forEach((key) => {
            //     if (key === 'images') {
            //         // Append each image file
            //         images.forEach((image) => {
            //             formData.append('images', image);
            //         });
            //     } else {
            //         formData.append(key, data[key]);
            //     }
            // });

            const response = await post_data("vendor/verify-otp-for-vendor-signup", formData);
            if (response?.status) {
                setTimeout(() => {
                    setLoading2(false)
                    // setOpen(false);
                    dispatch(login(response?.data?.user));
                    localStorage.setItem("authToken", response?.data.token);
                    navigate('/vendor/dashboard');
                    window.scrollTo(0, 0)
                }, 1000);
            } else {
                toast.error('Wrong Otp!');
            }
            setLoading2(false);
        }

        else if (type === 'change-email') {
            let payload = { email: email, otp: otp.join(""), new_email: newEmail, customer_id: user_data?._id };  // change customer email
            const response = await post_data("customer/verify-otp-for-change-email", payload);
            if (response?.status) {
                setTimeout(() => {
                    setLoading2(false)
                    setOpen(false);
                    dispatch(login(response?.data));
                    navigate('/dashboard');
                    window.scrollTo(0, 0)
                }, 1000);
            } else {
                toast.error('Wrong Otp!');
            }
            setLoading2(false);
        }

        else if (type === 'change-number') {
            let payload = { otp: otp.join(""), new_number: newNumber, customer_id: user_data?._id };  // change customer email
            const response = await post_data("customer/verify-otp-for-change-number", payload);
            if (response?.status) {
                setTimeout(() => {
                    setLoading2(false)
                    setOpen(false);
                    dispatch(login(response?.data));
                    navigate('/dashboard');
                    window.scrollTo(0, 0)
                }, 1000);
            } else {
                toast.error('Wrong Otp!');
            }
            setLoading2(false);
        }

        else if (type === 'change-admin-email') {
            let payload = { email: user_data?.email, otp: otp.join(""), new_email: newEmail };
            let response;
            if (isClients === 'isClients') {
                response = await post_data("client/verify-otp-for-change-email", payload);
            } else {
                response = await post_data("super-admin/verify-otp-for-change-email", payload);
            }

            if (response?.status === true) {
                setLoading2(false)
                dispatch(login(response?.data));
                navigate(isClients === 'isClients' ? '/company/dashboard/settings' : '/admin/dashboard/settings');
                window.scrollTo(0, 0)
            } else {
                toast.error(response?.data?.status?.message || 'Something Went Wrong!');
            }
            setLoading2(false);
        }
        // else if (user_data?.isSuperAdmin) {
        //     let payload = { phone: user_data?.phone, otp: otp.join(""), new_phone: mobileNumber };
        //     const response = await post_data("super-admin/verify-otp-for-change-phone", payload);
        //     if (response?.status) {
        //         setTimeout(() => {
        //             setLoading2(false)
        //             dispatch(login(response?.data));
        //             navigate('/admin/dashboard/settings');
        //             window.scrollTo(0, 0)
        //         }, 1000);
        //     } else {
        //         toast.error('Wrong Otp!');
        //     }
        //     setLoading2(false);
        // }
        else if (type === 'change-client-email') {
            let payload = { email: user_data?.email, otp: otp.join(""), new_email: newEmail };
            let response
            if (user_data?.isSubClient) {
                response = await post_data("sub-client/verify-otp-for-change-email", payload);
            } else if (isClients === 'isVendor') {

                response = await post_data("vendor/verify-otp-for-change-email", payload);
            } else if (isClients === "isUser") {

                response = await post_data("user/verify-otp-for-change-email", payload);
            }
             else {
                response = await post_data("client/verify-otp-for-change-email", payload);
            }

            if (response?.status) {
                setTimeout(() => {
                    setLoading2(false)
                    dispatch(login(response?.data));
                    isClients === 'isVendor' ? navigate('/vendor/dashboard/settings')
                        :
                        isClients === 'isUser' ? navigate('/userdashboard')
                            : navigate('/company/dashboard/settings');
                    window.scrollTo(0, 0)
                }, 1000);
            } else {
                toast.error('Wrong Otp!');
            }
            setLoading2(false);

        } else if (type === 'change-user-phone') {
            let payload = { phone: isClients === 'isVendor' || isClients === 'isUser' ? user_data?.phone : user_data?.contactNo, otp: otp.join(""), new_phone: mobileNumber };
            let response
            if (user_data?.isSubClient) {
                response = await post_data("sub-client/verify-otp-for-change-phone", payload);
            } else if (isClients === 'isVendor') {
                response = await post_data("vendor/verify-otp-for-change-phone", payload);
            } else if (isClients === "isUser") {

                response = await post_data("user/verify-otp-for-change-phone", payload);
            } else {
                response = await post_data("client/verify-otp-for-change-phone", payload);
            }

            if (response?.status === true) {
                setTimeout(() => {
                    setLoading2(false)
                    dispatch(login(response?.data));
                    isClients === 'isVendor' ? navigate('/vendor/dashboard/settings') : <>{isClients === 'isUser' ? navigate('//userdashboard') : navigate('/company/dashboard/settings')}</>
                    window.scrollTo(0, 0)
                }, 1000);
            } else {
                toast.error(response?.response?.data?.message || 'Something Went Wrong!');
            }
            setLoading2(false);
        } else if (type === 'user_signup') {
            let payload = { phone: mobileNumber, otp: otp.join("") };
            const response = await post_data("user/verify-otp-to-user-login", payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response?.status === true) {
                setTimeout(() => {
                    dispatch(login(response?.data?.user));
                    localStorage.setItem("authToken", response?.data?.token);
                    toast.success('Login Successfull!');
                    setLoading2(false)
                    setOpen(false);
                    window.scrollTo(0, 0)
                    navigate('/');
                }, 500);
            } else {
                toast.error('User not found!');
            }
            setLoading2(false);

        } else if (type === 'change-client-phone') {
            let payload = { phone: user_data?.phone, otp: otp.join(""), new_phone: mobileNumber };
            let response

            response = await post_data("client/verify-otp-for-change-phone", payload);

            if (response?.status) {
                setTimeout(() => {
                    setLoading2(false)
                    dispatch(login(response?.data));
                    navigate('/company/dashboard')
                    window.scrollTo(0, 0)
                }, 1000);
            } else {
                toast.error('Wrong Otp!');
            }
            setLoading2(false);
        }
        else if (type === 'change-user-phone') {
            let payload = { phone: user_data?.phone, otp: otp.join(""), new_phone: mobileNumber };
            let response

            response = await post_data("user/verify-otp-for-change-phone", payload);

            if (response?.status) {
                setTimeout(() => {
                    setLoading2(false)
                    dispatch(login(response?.data));
                    navigate('/userdashboard')
                    window.scrollTo(0, 0)
                }, 1000);
            } else {
                toast.error('Wrong Otp!');
            }
            setLoading2(false);
        }

    };

    const handleResendOtp = async () => {
        setTimer(59);
        setCanResend(false);
        handleResend()
    };


    const handleEnter = (event) => {
        if (event.key == 'Enter') {
            handleSubmit()
        }
    }

    const button = {
        padding: '8px 0',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: 0,
        cursor: 'pointer',
        width: '82%',
        // margin: '5% auto 2%'
    }

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="otp-form"  >
                    {otp?.map((digit, index) => {
                        return (
                            <>
                                <div
                                    style={{
                                        ...otpBox,
                                        border:
                                            focusedIndex === index
                                                ? `2px solid ${primaryColor}`
                                                : "2px solid #CBCBCB",
                                    }}
                                >
                                    <TextField
                                        onKeyPress={(event) => handleEnter(event)}
                                        onFocus={() => handleFocus(index)}
                                        value={digit}
                                        inputRef={(ref) => (otpInputRefs.current[index] = ref)}
                                        onChange={(e) =>
                                            handleOtpChange(index, e.target.value, "one")
                                        }
                                        sx={{
                                            input: {
                                                color: "black",
                                                fontSize: 30,
                                                textAlign: "center",
                                                fontWeight: "bold",
                                            },
                                        }}
                                        variant="standard"
                                        hiddenLabel
                                        placeholder=" "
                                        InputProps={{
                                            disableUnderline: true,
                                            inputProps: { maxLength: 1 },
                                        }}
                                    />
                                </div>
                            </>
                        );
                    })}
                    <p className="otp-form-txt" style={{ marginTop: '3%' }}>
                        Please enter your verification code.
                    </p>
                </div >

                <Button
                    disabled={loading2}
                    onClick={handleSubmit}
                    fullWidth
                    className={loading2 ? "register-btn-disable" : "register-btn"}
                    style={button}
                >
                    {loading2 ? "Verifying..." : "Verify"}
                </Button>

                <center>
                    {canResend ? (
                        <p onClick={handleResendOtp} style={{ cursor: 'pointer', color: primaryColor, fontWeight: 500, textDecoration: 'underline' }}>
                            Resend OTP
                        </p>
                    ) : (
                        <p className="otp-form-txt">
                            Didn't receive OTP? Resend in <span style={{ fontWeight: 600 }}>{`00:${timer < 10 ? `0${timer}` : timer} seconds`}</span>
                        </p>
                    )}
                </center>
                {/* <Toaster
                    position="top-center"
                    reverseOrder={false}
                /> */}
            </div>
        </>
    );
}
