import { Box, Button, Modal, Typography } from "@mui/material";
import { useState } from "react";

import { primaryColor } from "../../constant";
import { post_data } from "../../api";
import toast from "react-hot-toast";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function UninstallGpsStatusModal({ open, setOpen, detailOpen, type, clientId, getUninstallData, item }) {
    console.log('item',item)
    const [date, setDate] = useState(null)

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            border: 'none',
            bgcolor: 'white',
            borderRadius: '10px',
            boxShadow: 12,
            textAlign: 'center',
            height: '280px',


        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        row: {
            display: 'flex',
            width: '100%',
            gap: '10px',
            margin: '4% 0',
            fontWeight: 400,
            fontSize: 16,
        },
        dataLabel: {
            fontWeight: 400,
            fontSize: 16,
            width: '50%',
            textAlign: 'left'
        },
        dataValue: {
            fontWeight: 400,
            fontSize: 16,
            marginLeft: 'auto',
            width: '50%',
            textAlign: 'right'
        },
        icon: {
            width: 22,
        },
        headingTypography: {
            fontWeight: 500,
            fontSize: 22,
            marginBottom: '10px',
            textAlign: 'left',
            p: 3,
            borderBottom: '1px solid gainsboro'
        }
    };

    console.log('clientididi',clientId)

    const handleSubmit = async () => {

        let body = {
            vehicleNumber: item?.vehicleNumber,
            gpsStatus: 'UnInstalled',
            dateOfUninstall: date,
            clientId: clientId
        }

        let result = null
        if (type === 'uninstall') {
            result = await post_data('uninstall/change-status', body)
        } else if (type === 'returns') {
            result = await post_data('return/change-status', body)
        } else if (type === 'claims') {
            result = await post_data('claim/change-status', body)
        }

        if (result?.status === true) {
            toast?.success(result?.message)
            getUninstallData()
        } else {
            toast?.error(result?.message)
        }
        setDate(null);
        setOpen(false);
        detailOpen(false);
    }

    return <Modal
        open={open}
        onClose={() => setOpen(false)}

    >
        <Box sx={styles.modalBox}>
            <Typography id="logout-modal-title" variant="h6" sx={styles.headingTypography}>
                Uninstall Gps
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker value={date} onChange={(newValue) => setDate(newValue)}
                            label="Select Date"

                        />
                    </DemoContainer>
                </LocalizationProvider>
            </div>

            <Button onClick={handleSubmit} variant="contained" style={{ marginTop: '10px' }} >Click to {type}</Button>
        </Box>
    </Modal>
}