
import { Button, Grid } from "@mui/material";
import { createButtonBg } from "../../../constant";
import { IoMdAdd } from "react-icons/io";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api"
import EmptyPage from "../../../components/EmptyPage";
import ClientTable from "../../../components/tables/ClientTable";
import { useNavigate } from "react-router-dom";
import SearchComponent from "../../../components/SearchComponent";
import AdminClientModal from "../../../components/tables/AdminChangPasswordModals";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ClientsTransactionModal from "../../../components/models/ClientsTransactionModal";
import InventoryTable from "../../../components/tables/InventoryTable";
import InventoryModales from "../../../components/models/InventoryModales";

const rangePickerStyle = {
    display: 'flex',
    cursor: 'pointer',
    fontSize: 14,
    background: 'white',
    padding: '0% 0% 0% 0%',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    color: '#7E8299',
    cursor: 'pointer'
};


export default function Inventorys() {

    const page = { width: '100%', marginBottom: '2%' };
    const navigate = useNavigate();
    const [createClientOpen, setCreateClientOpen] = useState(false)
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [value, setValue] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(false)
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [clientsData, setClientData] = useState([])

    const itemsPerPage = 15;
    const count = data?.length;

    const handleSearch = async () => {
        if (value.length > 0) {
            try {
                const data = await get_data(`inventory/search-inventory/${value}?pageNumber=${currentPage}`)
                // console.log('data', data)
                if (data.status) {
                    setData(data?.data?.inventories)
                    setTotalPages(data?.data?.totalPages)
                    setTotalData(data?.data?.totalInventory)
                }
            }
            catch (error) {
                // console.log('client serarch error : ', error)
            }

        } else {
            getInvenrotyData(currentPage)
        }
    }


    const tableHeader = [
        "S No.", 
        "Company Name",
        "Total Stock",
        "Active Stock",
         "Non Active Stock",
        // 'Status',
        "Date",

    ]

    const getInvenrotyData = async () => {
        try {
            const data = await get_data(`inventory/get-all-inventory?pageNumber=${currentPage}`);
            if (data.status) {
                setData(data?.data?.inventories);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalInventory);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    // console.log('client data : ', data)


    useEffect(() => {
        if (currentPage) {
            localStorage.removeItem('clientId')
            getInvenrotyData(currentPage);
        }
    }, [currentPage])

    const handleNavigate = () => {
        // setCreateClientOpen(true)
        localStorage.setItem('inventoryId', false);
        navigate('/admin/dashboard/inventory/create', { state: { isUpadate: false } })
        window.scrollTo(0, 0)
    }

    const handleDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
        }
    };

    const fetchExportData = async () => {
        // try {

        //     const fromDate = moment(startDate).toISOString();
        //     const toDate = moment(endDate).toISOString();

        //     let response = await post_data('inventory/get-all-client-by-date', {
        //         from: fromDate,
        //         to: toDate
        //     })
        //     if (response?.status) {
        //         setClientData(response?.data?.ClientRecords)
        //         setOpen(true)
        //         setFromDate(response?.data?.fromDate)
        //         setToDate(response?.data?.toDate)
        //     } else {

        //     }
        // } catch (error) {
        // }
    };



    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>

                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        {/* <div style={{ ...rangePickerStyle, border: 'none', }}>
                            <DatePicker
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd-MM-yyyy"
                                maxDate={new Date()}
                                customInput={
                                    <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                        {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                    </button>
                                }
                            />
                        </div> */}

                        {/* <Button
                            onClick={fetchExportData}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Export
                        </Button> */}

                        {/* <Button
                            onClick={handleNavigate}
                            startIcon={<IoMdAdd style={{ color: 'white' }} />}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Create
                        </Button> */}
                        {/* <InventoryModales /> */}
                    </div>
                </div>

                {
                    data?.length === 0 ?
                        <>
                            <EmptyPage tableHeader={tableHeader} />
                        </>
                        :
                        <>
                            <InventoryTable
                                data={data}
                                tableHeader={tableHeader}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                count={count}
                                totalData={totalData}
                                totalPages={totalPages}
                                loading={loading}
                                setLoading={setLoading}
                                getInvenrotyData={getInvenrotyData}

                            />

                            <ClientsTransactionModal
                                open={open}
                                setOpen={setOpen}
                                fromDate={fromDate}
                                toDate={toDate}
                                data={clientsData}
                            />
                        </>
                }
            </Grid>
        </>
    )
}