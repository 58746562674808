import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as XLSX from 'xlsx';
import { formatDate, formatPrice, primaryColor } from "../../constant";
import EmptyPage from "../EmptyPage";

const RecordTransactionModal = ({ openTransaction, setOpenTransaction, data, fromDate, toDate, type }) => {
    const contentRef = useRef(null);

    const styles = {
        invoice: {
            padding: "4%",
            height: 470,
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #000",
            paddingBottom: "10px",
            marginBottom: "5%",
        },
        brand: {
            textAlign: "left",
        },
        info: {
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        infoSection: {
            textAlign: "left",
        },
        table: {
            width: "100%",
            borderCollapse: "collapse",
        },
        th: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        td: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        footer: {
            marginTop: "20px",
            borderTop: "2px solid #000",
            marginTop: "5%",
            paddingTop: "10px",
            display: "flex",
            justifyContent: "space-between",
        },
        terms: {
            textAlign: "left",
        },
        payment: {
            textAlign: "left",
        },
        summary: {
            marginTop: "20px",
            textAlign: "right",
        },
        text: {
            fontSize: 14,
        },
    };


    const handleDownloadPdf = async () => {
        if (contentRef.current) {
            const pdf = new jsPDF("p", "mm", "a4");
            const pageWidth = 210; // A4 page width in mm
            const pageHeight = 295; // A4 page height in mm
            const margin = 10;
            const tableStartY = 20; // Starting point for the table
            const rowHeight = 8; // Reduced row height for better fit
            const headerHeight = 10; // Height for the table header
            const rowsPerPage = Math.floor((pageHeight - tableStartY - margin) / rowHeight) - 1; // Calculate rows per page

            const dataClone = [...data];
            let positionY = tableStartY;
            let pageNumber = 1;
    
            while (dataClone.length > 0) {
                const currentPageData = dataClone.splice(0, rowsPerPage);
    
                if (pageNumber === 1) {
                    pdf.setFontSize(14);
                    pdf.text("Report", margin, positionY); // Title on top
                    positionY += 10; // Add space after title
                }
    
                pdf.setFontSize(10);
                pdf.text("Company Name", margin, positionY);
                pdf.text("Vehicle No.", margin + 30, positionY);
                pdf.text("IMEI No.", margin + 60, positionY);
                pdf.text("SIM No.", margin + 90, positionY);
                pdf.text("Device UniqueId", margin + 110, positionY);
                pdf.text("Driver Name", margin + 140, positionY);
                pdf.text("Driver ContactNo", margin + 170, positionY);
                pdf.text("Installer", margin + 200, positionY);
                pdf.text("Claim Date", margin + 230, positionY);
                pdf.text("Return Date", margin + 260, positionY);
                pdf.text("Install Date", margin + 290, positionY);
                pdf.text("Uninstall Date", margin + 320, positionY);
                pdf.text("Status", margin + 350, positionY);
    
                positionY += headerHeight;

                currentPageData.forEach((item, i) => {
                    pdf.setFontSize(8); // Reduced font size for better fit
                    pdf.text(item?.clientId?.companyName || '', margin, positionY);
                    pdf.text(item?.vehicleNumber || '', margin + 30, positionY);
                    pdf.text(item?.imei || '-', margin + 60, positionY);
                    pdf.text(item?.simNumber || '-', margin + 90, positionY);
                    pdf.text(item?.deviceUniqueId || '-', margin + 110, positionY);
                    pdf.text(item?.driverDetails?.name || '-', margin + 140, positionY);
                    pdf.text(item?.driverDetails?.contactNo || '-', margin + 170, positionY);
                    pdf.text(item?.installer || '-', margin + 200, positionY);
                    pdf.text(formatDate(item?.claimDate) || '', margin + 230, positionY);
                    pdf.text(formatDate(item?.returnDate) || '', margin + 260, positionY);
                    pdf.text(formatDate(item?.installDate) || '', margin + 290, positionY);
                    pdf.text(formatDate(item?.uninstallDate) || '', margin + 320, positionY);
                    pdf.text(item?.gpsStatus || '-', margin + 350, positionY);
    
                    positionY += rowHeight; // Move to next row
                });
                
                if (dataClone.length > 0) {
                    pdf.addPage();
                    positionY = tableStartY;
                    pageNumber++;
                }
            }
    
            pdf.save("invoice.pdf");
        }
    };
    


    // const handleDownloadExcel = () => {
    //     let excelData
    //     if (data && data.length > 0) {
    //         excelData = data?.map(item => ({
    //             'Company Name': item?.clientId?.companyName || '',
    //             'Vehicle No.': item?.vehicleNumber || '',
    //             'IMEI No.': item?.imei || '-',
    //             'SIM No.': item?.simNumber || '-',
    //             'Device UniqueId': item?.deviceUniqueId || '-',
    //             'Driver Name': item?.driverDetails?.name || '-',
    //             'Driver ContactNo': item?.driverDetails?.contactNo || '-',
    //             'Installer': item?.installer || '-',
    //             'Claim Date': formatDate(item?.claimDate) || '',
    //             'Return Date': formatDate(item?.returnDate) || '',
    //             'Installed date': formatDate(item?.installDate) || '',
    //             'Un-installed date': formatDate(item?.uninstallDate) || '',
    //             'Status': item?.gpsStatus || '',

    //         }));


    //         // Create a new workbook and add the data
    //         const worksheet = XLSX.utils.json_to_sheet(excelData);
    //         const workbook = XLSX.utils.book_new();
    //         XLSX.utils.book_append_sheet(workbook, worksheet, "Invoices");

    //         // Download the file
    //         XLSX.writeFile(workbook, "invoices.xlsx");
    //     }
    // };

    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 1200,
        maxHeight: '90%',
        borderRadius: 2,
        overflowY: 'auto',
        bgcolor: "white",
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        border: "none",
        boxShadow: 24,
        padding: '0 0 5%',
    };

    const button = {
        padding: '5px',
        fontSize: 13,
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '15%',
        marginTop: '20px',
        boxShadow: 'none',
        marginLeft: '2%'
    }

    return (
        <>
            <Modal
                open={openTransaction}
                onClose={() => setOpenTransaction(false)}
            >
                <Box sx={styleModal}>
                    {
                        data?.length === 0 ? (
                            <EmptyPage />
                        ) : (
                            <>
                                <Button variant="contained" onClick={() => { handleDownloadPdf() }} style={button}>Download</Button>
                                <div ref={contentRef} id="invoice" style={styles.invoice}>
                                    <div style={styles.header}>
                                        <div style={styles.brand}>
                                            <h2 style={{ fontWeight: 600, margin: 0, }}>Record's Details</h2>
                                        </div>
                                        {/* <h2 style={{ fontWeight: 600, margin: 0 }}>Uninstall INVOICE</h2> */}
                                    </div>

                                    <table style={{ ...styles.table, ...styles.text, margin: "3% 0" }}>
                                        <thead style={{ textAlign: "left" }}>
                                            <tr>
                                                <th>Company Name</th>
                                                <th>Vehicle No.</th>
                                                <th>IMEI No.</th>
                                                <th>SIM No.</th>
                                                <th>Device UniqueId</th>
                                                <th>Driver Name</th>
                                                <th>Driver ContactNo</th>
                                                <th>Installer</th>
                                                <th>Claim Date</th>
                                                <th>Return Date</th>
                                                <th>Installed date</th>
                                                <th>Un-installed date</th>
                                                <th>Status</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {
                                                data?.map((item, i) => {
                                                    return (
                                                        <>

                                                            <tr style={{ margin: 0, borderBottom: '1px solid gainsboro' }}>
                                                                <td style={{ padding: '1% 0', textAlign: 'center' }}>{item?.clientId?.companyName || '-'}</td>
                                                                <td style={{ padding: '1% 0', textAlign: 'center' }}>{item?.vehicleNumber || ''}</td>
                                                                <td style={{ padding: '1% 0', textAlign: 'center' }}>{item?.imei || '-'}</td>
                                                                <td style={{ padding: '1% 0', textAlign: 'center' }}>{item?.simNumber || '-'}</td>
                                                                <td style={{ padding: '1% 0', textAlign: 'center' }}>{item?.deviceUniqueId || '-'}</td>

                                                                <td style={{ padding: '1% 0', textAlign: 'center' }}>{item?.driverDetails?.name || '-'}</td>
                                                                <td style={{ padding: '1% 0', textAlign: 'center' }}>{item?.driverDetails?.contactNo || '-'}</td>

                                                                <td style={{ padding: '1% 0', textAlign: 'center' }}>{item?.installer}</td>
                                                                <td style={{ padding: '1% 0' }}>{formatDate(item?.claimDate) || '-'}</td>
                                                                <td style={{ padding: '1% 0' }}>{formatDate(item?.returnDate) || ''}</td>
                                                                <td style={{ padding: '1% 0' }}>{formatDate(item?.installDate) || '-'}</td>
                                                                <td style={{ padding: '1% 0' }}>{formatDate(item?.uninstallDate) || '-'}</td>
                                                                <td style={{ padding: '1% 0', textAlign: 'center' }}>{item?.gpsStatus || '-'}</td>

                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div style={{ ...styles.footer, ...styles.text }}>
                                        <div style={styles.terms}>
                                            <p>All Invoices are from {formatDate(fromDate)} to {formatDate(toDate)} period.</p>
                                            <p>For GPS</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </Box>
            </Modal>
        </>
    );
};

export default RecordTransactionModal;