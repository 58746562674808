
import { Button, Grid } from "@mui/material";
import RequestsTable from "../../../components/RequestsTable";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get_data, post_data } from "../../../api";
import EmptyPage from "../../../components/EmptyPage";
import { Toaster } from "react-hot-toast";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { IoMdAdd } from "react-icons/io";
import { createButtonBg } from "../../../constant";
import SearchComponent from "../../../components/SearchComponent";
import RequestsTransactionModal from "../../../components/models/RequestsTransactionModal";

const rangePickerStyle = {
    display: 'flex',
    cursor: 'pointer',
    fontSize: 14,
    background: 'white',
    padding: '0% 0% 0% 0%',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    color: '#7E8299',
    cursor: 'pointer'
};


export default function Requests() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate()
    const [value, setValue] = useState('')
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(false)
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [requestsData, setRequestsData] = useState([]);

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const getAllRequests = async (page) => {
        try {
            const data = await get_data(`request/get-all-request-with-pagination?pageNumber=${page || currentPage}`);
            if (data.status) {
                setLoading(false);
                setData(data?.data?.requests);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalRequests);
                if (page) {
                    setCurrentPage(page);
                }
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            if (value.length > 0) {
                handleSearch(currentPage);
            } else {
                getAllRequests(currentPage);
            }
        }
    }, [currentPage])

    const itemsPerPage = 15;
    const count = data?.length;

    const tableHeader = [
        "S No.", "Company Name", "Vehicles Number", "IMEI Number", "Device UniqueId", "Driver", "GPS Devices", "Request ID", "Installer", "Date"
    ]


    const handleSearch = async (page) => {
        setLoading(true);
        if (value.length > 0) {
            const data = await get_data(`request/search-request/${value}?pageNumber=${page || 1}`)
            if (data.status) {
                setData(data?.data?.requests)
                setTotalData(data?.data?.totalRequests)
                setTotalPages(data?.data?.totalPages)
                setCurrentPage(page || 1);
            }
        } else {
            getAllRequests(1);
        }
        setLoading(false);
    }


    const handleDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
        }
    };

    const fetchExportData = async (search) => {
        try {

            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();

            let response = await post_data('request/get-all-request-by-date', {
                from: fromDate,
                to: toDate
            })
            if (response?.status) {
                setRequestsData(response?.data?.requests)
                setData(response?.data?.requests)
                setOpen(true)
                setFromDate(response?.data?.fromDate)
                setToDate(response?.data?.toDate)
            } else {

            }
        } catch (error) {
        }
    };


    return (
        <>  <Toaster position="top-right" reverseOrder={false} />
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <div style={{ ...rangePickerStyle, border: 'none', }}>
                            <DatePicker
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd-MM-yyyy"
                                maxDate={new Date()}
                                customInput={
                                    <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                        {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                    </button>
                                }
                            />
                        </div>
                        <Button
                            onClick={fetchExportData}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Export
                        </Button>

                    </div>

                </div>
                {
                    data?.length === 0 ?
                        <>
                            <EmptyPage  tableHeader={tableHeader}/>
                        </>
                        :
                        <>
                            <RequestsTable
                                type='requests'
                                data={data}
                                tableHeader={tableHeader}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                count={count}
                                totalData={totalData}
                                totalPages={totalPages}
                                loading={loading}
                                setLoading={setLoading}
                                getAllRequests={getAllRequests} />

                            <RequestsTransactionModal
                                open={open}
                                setOpen={setOpen}
                                fromDate={fromDate}
                                toDate={toDate}
                                data={requestsData}
                            />
                        </>}
            </Grid>
        </>
    )
}