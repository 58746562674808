import { Button, Grid } from "@mui/material";
import VendorClientsTable from "../../../components/tables/VendoreClientsTable";
import { useEffect, useState } from "react";
import { get_data } from "../../../api";
import EmptyPage from "../../../components/EmptyPage";
import { useSelector } from "react-redux";

export default function VendorClients() {

    const { user_data } = useSelector(state => state.user);
    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);

    const getAllClients = async () => {
        try {
            const data = await get_data(`vendor/get-client-of-vendor-by-pagination?pageNumber=${currentPage}`);
            console.log("vvvvvvvvv",data)
            if (data.status) {
                setLoading(false);
                setData(data?.data?.clients);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalClients);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllClients(currentPage);
        }
    }, [currentPage])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);


    const itemsPerPage = 15;
    const count = data?.length;

    const tableHeader = [
        "S No.", "Comapny Name", "Company ID", "Contact", "Email", "Address",
    ]

    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>
                {
                    data?.length === 0 ?
                        <>
                            <EmptyPage tableHeader={tableHeader}/>
                        </>
                        :
                        <>
                            <VendorClientsTable
                                data={data}
                                tableHeader={tableHeader}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                count={count}
                                totalData={totalData}
                                totalPages={totalPages}
                                loading={loading}
                                setLoading={setLoading}
                                getAllClients={getAllClients}
                            />
                        </>
                }
            </Grid>
        </>
    )
}