import { Button, Grid } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import { createButtonBg } from "../../../constant";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { get_data } from "../../../api";
import EmptyPage from "../../../components/EmptyPage";
import SearchComponent from "../../../components/SearchComponent";
import VendorVehicleTable from "../../../components/tables/VendorVehicleTable";
import { useSelector } from "react-redux";
import ClientVehicleTable from "../../../components/tables/ClientVehicleTable";

export default function ClientVehicle() {

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const navigate = useNavigate();
    const { isLoggedIn, user_data } = useSelector(state => state.user);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [value, setValue] = useState('')

    const getAllVehicles = async () => {
        try {
            const data = await get_data(`vehicles/get-vehicles-by-client/${user_data?._id}?pageNumber=${currentPage}`);
            console.log('FFFF', data)
            if (data.status === true) {
                setLoading(false);
                setData(data?.data?.vehicles);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalVehicles);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllVehicles(currentPage);
        }
    }, [currentPage])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);


    const itemsPerPage = 15;
    const count = data?.length;


    const tableHeader = [
        "S No.","Vendor Name", "Owner Name", "Contact", "Vehicle Number", " Type Of Vehicle  ","Vendor gps Request","Plate", "Vehicle Class", "Status"
    ]

    const handleNavigate = () => {
        navigate('/company/dashboard/vehicles/create')
        window.scrollTo(0, 0)
    }

    const handleSearch = async () => {
        // if (value.length > 0) {
        //     try {
        //         const data = await get_data(`product/search-product/${value}?pageNumber=${currentPage}`)
        //         if (data.status) {
        //             setData(data?.data?.products)
        //             setTotalPages(data?.data?.totalPages)
        //             setTotalData(data?.data?.totalProducts)
        //         }
        //     }
        //     catch (error) {
        //         console.log('client serarch error : ', error)
        //     }

        // } else {
        //     getAllProducts(currentPage)
        // }
    }

    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div>
                    {/* <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <Button
                            onClick={handleNavigate}
                            startIcon={<IoMdAdd style={{ color: 'white' }} />}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Create
                        </Button>
                    </div> */}
                </div>
                {
                    data?.length === 0 ?
                        <>
                            <EmptyPage tableHeader={tableHeader} />
                        </>
                        :
                        <>
                            <ClientVehicleTable
                                data={data}
                                tableHeader={tableHeader}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                count={count}
                                totalData={totalData}
                                totalPages={totalPages}
                                loading={loading}
                                setLoading={setLoading}
                                getAllVehicles={getAllVehicles}
                            />
                        </>
                }
            </Grid>
        </>
    )
}