import { Button, Grid } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import { createButtonBg } from "../../../constant";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api";
import EmptyPage from "../../../components/EmptyPage";
import BrandsTable from "../../../components/tables/BrandsTable";
import BrandModal from "../../../components/models/BrandsModals";
import { Toaster } from "react-hot-toast";
import SearchComponent from "../../../components/SearchComponent";

export default function Brands() {

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [createBrandOpen, setCreateBrandOpen] = useState(false);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [refresh, setRefresh] = useState(false)
    const [value, setValue] = useState('')

    const getAllBrands = async () => {
        try {
            const data = await get_data(`brand/get-all-brands?pageNumber=${currentPage}`);
            if (data.status) {
                setLoading(false);
                setData(data?.data?.brands);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalBrands);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllBrands(currentPage);
        }
    }, [currentPage, refresh])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);

    const handleSearch = async () => {
        setLoading(true);
        if (value.length > 0) {
            const data = await get_data(`brand/search-brands/${value}?pageNumber=${currentPage}`)
            if (data.status) {
                setData(data?.data?.brands);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalBrands);
            }
        } else {
            getAllBrands(currentPage);
        }
        setLoading(false);
    }

    const itemsPerPage = 15;
    const count = data?.length;


    const tableHeader = [
        "S No.", 'Brand Name'
    ]

    const handleCreate = () => {
        setCreateBrandOpen(true)
    }

    return (
        <>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <Button
                            onClick={handleCreate}
                            startIcon={<IoMdAdd style={{ color: 'white' }} />}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Create
                        </Button>
                        <BrandModal createBrandOpen={createBrandOpen} setCreateBrandOpen={setCreateBrandOpen} isUpdate={false} refresh={refresh} setRefresh={setRefresh} />
                    </div>

                </div>

                {
                    data?.length === 0 ?
                        <>
                            <EmptyPage tableHeader={tableHeader} />
                        </>
                        :
                        <>
                            <BrandsTable
                                data={data}
                                tableHeader={tableHeader}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                count={count}
                                totalData={totalData}
                                totalPages={totalPages}
                                loading={loading}
                                setLoading={setLoading}
                                getAllBrands={getAllBrands}
                                refresh={refresh} setRefresh={setRefresh}
                            />
                        </>
                }
            </Grid>

        </>
    )
}