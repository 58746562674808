import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useState, useEffect } from "react";
import { createButtonBg, primaryColor } from '../../constant';
import { get_data, post_data } from '../../api';
import { Toaster, toast } from 'react-hot-toast';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
    height: "80vh",
    overflow: 'auto'
};

export default function AdminRecordModal({ editData, isUpdate, open, setOpen, getAllRecords }) {
    const [formData, setFormData] = useState({});
    const [companyList, setCompanyList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [errors, setErrors] = useState({});

    console.log('formadatea', editData)

    useEffect(() => {
        if (isUpdate && editData) {
            setFormData({
                clientId: editData?.clientId?._id || '',
                vehicleNumber: editData?.vehicleNumber || '',
                imei: editData?.imei || '',
                simNumber: editData?.simNumber || '',
                deviceUniqueId: editData?.deviceUniqueId || '',
                installer: editData?.installer || '',
                driverDetails: {
                    name: editData?.driverDetails?.name,
                    contactNo: editData?.driverDetails?.contactNo,
                },
                claimDate: editData?.claimDate ? dayjs(editData?.claimDate) : null,
                returnDate: editData?.returnDate ? dayjs(editData?.returnDate) : null,
                installData: editData?.installDate ? dayjs(editData?.installDate) : null,
                uninstallDate: editData?.uninstallDate ? dayjs(editData?.uninstallDate) : null,
                gpsStatus: editData?.gpsStatus || '',
            });
        } else {
            setFormData('');
        }
    }, [editData, isUpdate]);

    console.log('gpsatat', formData)

    const fetchAllCompany = async () => {
        const result = await get_data(`client/get-all-clients-for-options`);
        setCompanyList(result?.data || []);
    };

    useEffect(() => {
        fetchAllCompany();
    }, []);


    const fetchAllVendor = async () => {
        const result = await get_data(`vendor/get-all-vendors-with-pagination`);
        setVendorList(result?.data?.vendors || []);
    };

    useEffect(() => {
        fetchAllVendor();
    }, []);

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validation = () => {
        let error = false;
        // if (!formData?.driverDetails?.name) {
        //     error = true;
        //     handleError('Please input Brand Name...', 'name');
        // }
        return error;
    };

    const handleClose = () => {
        setOpen(false);
        setFormData({});
    };

    const handleSubmit = async () => {
        const hasError = validation();

        if (!hasError) {

            try {
                let data;
                if (isUpdate) {
                    data = await post_data(`record/update-record/${editData?._id}`, { ...formData });
                } else {
                    data = await post_data(`record/create-record`, { ...formData });
                }
                setOpen(false);

                if (data?.status === true) {
                    getAllRecords()
                    setFormData({});
                    toast.success(data?.message || 'Operation successful!');
                } else {
                    toast.error('Something went wrong!');
                }
            } catch (error) {
                toast.error('An error occurred. Please try again.');
            }
        }
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h6">
                            {isUpdate ? 'Edit Record' : 'Create Record'}
                        </Typography>
                        <Grid container spacing={2} style={{ marginTop: 10 }}>
                            {/* Company Select */}
                            <Grid item xs={12}>
                                <Autocomplete
                                    fullWidth
                                    options={companyList}
                                    value={companyList?.find(c => c._id === formData?.clientId) || null}
                                    onChange={(event, newValue) => setFormData({ ...formData, clientId: newValue?._id })}
                                    getOptionLabel={(option) => option.companyName || ''}
                                    renderInput={(params) => <TextField {...params} label="Select Company" />}
                                />
                            </Grid>
                            <Grid item xs={12}><TextField error={Boolean(errors.vehicleNumber)} helperText={errors.vehicleNumber} onFocus={() => handleError('', 'vehicleNumber')} value={formData?.vehicleNumber || ''} label="Vehicle Number" onChange={(e) => setFormData({ ...formData, vehicleNumber: e.target.value })} fullWidth /></Grid>
                            <Grid item xs={6}><TextField error={Boolean(errors.imei)} helperText={errors.imei} onFocus={() => handleError('', 'imei')} value={formData?.imei || ''} label="IMEI Number" onChange={(e) => setFormData({ ...formData, imei: e.target.value })} fullWidth /></Grid>
                            <Grid item xs={6}><TextField error={Boolean(errors.simNumber)} helperText={errors.simNumber} onFocus={() => handleError('', 'simNumber')} value={formData?.simNumber || ''} label="Sim Number" onChange={(e) => setFormData({ ...formData, simNumber: e.target.value })} fullWidth /></Grid>
                            <Grid item xs={12}><TextField error={Boolean(errors.deviceUniqueId)} helperText={errors.deviceUniqueId} onFocus={() => handleError('', 'deviceUniqueId')} value={formData?.deviceUniqueId} label="Device Unique ID" onChange={(e) => setFormData({ ...formData, deviceUniqueId: e.target.value })} fullWidth /></Grid>
                            <Grid item xs={12}><TextField error={Boolean(errors.installer)} helperText={errors.installer} onFocus={() => handleError('', 'installer')} value={formData?.installer || ''} label="installer" onChange={(e) => setFormData({ ...formData, installer: e.target.value })} fullWidth /></Grid>
                            <Grid item xs={6}><TextField error={Boolean(errors.name)} helperText={errors.name} onFocus={() => handleError('', 'name')} value={formData?.driverDetails?.name} label="Driver Name" onChange={(e) => setFormData({ ...formData, driverDetails: { ...formData.driverDetails, name: e.target.value } })} fullWidth /></Grid>
                            <Grid item xs={6}><TextField error={Boolean(errors.contactNo)} helperText={errors.contactNo} onFocus={() => handleError('', 'contactNo')} value={formData?.driverDetails?.contactNo} label="Driver Contact No" onChange={(e) => setFormData({ ...formData, driverDetails: { ...formData.driverDetails, contactNo: e.target.value } })} fullWidth /></Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Cliam Data"
                                        value={formData?.claimDate || null}
                                        onChange={(newValue) => setFormData({ ...formData, claimDate: newValue })}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Return Date"
                                        value={formData?.returnDate || null}
                                        onChange={(newValue) => setFormData({ ...formData, returnDate: newValue })}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Install Date"
                                        value={formData?.installData || null}
                                        onChange={(newValue) => setFormData({ ...formData, installDate: newValue })}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Uninstall Date"
                                        value={formData?.uninstallDate || null}
                                        onChange={(newValue) => setFormData({ ...formData, uninstallDate: newValue })}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Autocomplete
                                    fullWidth
                                    options={vendorList}
                                    value={vendorList?.find(v => v._id === formData?.vendorId) || null}
                                    onChange={(event, newValue) => setFormData({ ...formData, vendorId: newValue?._id })}
                                    getOptionLabel={(option) => option.name || ''}
                                    renderInput={(params) => <TextField {...params} label="Select Vendor" />}
                                />
                            </Grid> */}

                            {/* Status Select */}
                            {/* <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        label="Status"
                                        value={formData?.gpsStatus || editData?.gpsStatus}
                                        onChange={(e) => setFormData({ ...formData, gpsStatus: e.target.value })}
                                    >
                                        <MenuItem value="active">Active</MenuItem>
                                        <MenuItem value="non-active">Non Active</MenuItem>
                                        <MenuItem value="no data">No Data</MenuItem>
                                        <MenuItem value="stock">Stock</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid> */}

                            {/* Action Buttons */}
                            <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        textTransform: 'capitalize',
                                        color: primaryColor,
                                        border: `1px solid ${primaryColor}`,
                                        borderRadius: 0
                                    }}
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{
                                        textTransform: 'capitalize',
                                        color: '#fff',
                                        background: primaryColor,
                                        borderRadius: 0
                                    }}
                                    onClick={handleSubmit}
                                    fullWidth
                                >
                                    {isUpdate ? 'Edit' : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
