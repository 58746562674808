
import { Button, Grid } from "@mui/material";
import { createButtonBg } from "../../../constant";
import { IoMdAdd } from "react-icons/io";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api";
import EmptyPage from "../../../components/EmptyPage";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import SearchComponent from "../../../components/SearchComponent";
import ClaimsTransactionModal from "../../../components/models/ClaimsTransactionModal";
import { useDispatch, useSelector } from "react-redux";
import ClientSitesForm from "../../../components/models/ClientSitesForm";
import ClientsInvoiceTable from "../../../components/tables/ClientsInvoiceTable";
import InvoiceTransactionModal from "../../../components/models/InvoiceTransactionModal";

const rangePickerStyle = {
    display: 'flex',
    cursor: 'pointer',
    fontSize: 14,
    background: 'white',
    padding: '0% 0% 0% 0%',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    color: '#7E8299',
    cursor: 'pointer'
};

export default function ClientInvoice() {

    const page = { width: '100%', marginBottom: '2%' };
    const { user_data } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [value, setValue] = useState('')
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(false)
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [claimsData, setClaimsData] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [openForm, setOpenForm] = useState(false)
    const itemsPerPage = 15;
    const count = data?.length;



    const tableHeader = [
        "S No.", 'Invoice No', 'transaction No', 'paymentAmount', 'Date'
    ]

    const getClaimData = async () => {

        try {
            let data
            user_data?.isSubClient ?
                data = await get_data(`invoice/get-all-invoices-for-sub-client/${user_data?._id}?pageNumber=${currentPage}`) :
                data = await get_data(`invoice/get-all-invoices-for-client/${user_data?._id}?pageNumber=${currentPage}`);

            console.log("dataINVE", data)
            if (data.status) {
                setLoading(false);
                setData(data?.data?.invoices);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalInvoices);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getClaimData(currentPage);
        }
    }, [currentPage, refresh])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);

    const handleSearch = async () => {
        setLoading(true);
        if (value.length > 0) {
            let data
            user_data?.isSubClient ?
                data = await get_data(`invoice/search-invoice-for-sub-client/${value}?pageNumber=${currentPage}`) :
                data = await get_data(`invoice/search-invoice-for-client/${value}?pageNumber=${currentPage}`)
            if (data.status === true) {
                setLoading(false);
                setData(data?.data?.invoices);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalInvoices);
            }
        } else {
            getClaimData(currentPage);
        }
        setLoading(false);
    }


    const handleDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
        }
    };

    const fetchExportData = async () => {
        try {

            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();

            let response
            user_data?.isSubClient ?
                response = await post_data('invoice/get-all-sales-by-date-for-sub-client', {
                    from: fromDate,
                    to: toDate
                }) :
                response = await post_data('invoice/get-all-sales-by-date-for-client', {
                    from: fromDate,
                    to: toDate
                })

            if (response?.status) {
                setClaimsData(response?.data?.invoice)
                setOpen(true)
                setFromDate(response?.data?.fromDate)
                setToDate(response?.data?.toDate)
            } else {

            }
        } catch (error) {
        }
    };

    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>

                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <div style={{ ...rangePickerStyle, border: 'none', }}>
                            <DatePicker
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd-MM-yyyy"
                                maxDate={new Date()}
                                customInput={
                                    <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                        {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                    </button>
                                }
                            />
                        </div>
                        <Button
                            onClick={fetchExportData}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Export
                        </Button>
                        {/* <AddRequestsModal title={'Requests'} /> */}

                        {/* <Button
                            onClick={() => setOpenForm(true)}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Create
                        </Button> */}
                        {/* <ClientSitesForm openForm={openForm} setOpenForm={setOpenForm} isUpdate={false} getClaimData={getClaimData} /> */}
                    </div>

                </div>

                {
                    data?.length === 0 ?
                        <>
                            <EmptyPage tableHeader={tableHeader} />
                        </>
                        :
                        <>
                            <ClientsInvoiceTable
                                data={data}
                                tableHeader={tableHeader}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                count={count}
                                totalData={totalData}
                                totalPages={totalPages}
                                loading={loading}
                                setLoading={setLoading}
                                refresh={refresh}
                                setRefresh={setRefresh}
                                getClaimData={getClaimData}
                            />

                            <InvoiceTransactionModal
                                type={'invoice'}
                                open={open}
                                setOpen={setOpen}
                                fromDate={fromDate}
                                toDate={toDate}
                                data={claimsData}
                            />
                        </>
                }
            </Grid>
        </>
    )
}