
import styled from 'styled-components';
import { useState } from 'react';
import { TextField, Button, IconButton, InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { toast } from 'react-toastify';
import { post_data } from '../api';
import { primaryColor } from '../constant';
import OtpComponent from './OtpComponent';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
  width:100vw; 
  height: 100vh;
`;

const LoginBox = styled.div`
  display: flex;
  width: 100%;
  height:100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const FormSection = styled.div`
  width: 25%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  boxSizing:border-box;
`;

const ImageSection = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.h2`
  margin-bottom: 1px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;

const Subtitle = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  color: #666;
  text-align: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Link = styled.a`
  font-size: 14px;
  color: #6c63ff;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const BottomLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

function ChangeAdminEmail() {
    const location = useLocation()
    const isClients = location.state
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState('');
    const [loader, setLoader] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [otpBox, setOtpBox] = useState(false)
    const [emailForm, setEmailForm] = useState(true)
    const [isAdmin, setIsAdmin] = useState(false)
    const { user_data } = useSelector(state => state.user);

    const dispatch = useDispatch();
    const handleError = (label, value) => {
        setErrors((prev) => ({ ...prev, [label]: value }))
    }

    const validate = () => {
        let error = false;

        if (email.length === 0) {
            error = true;
            handleError('email', 'Email is Required')
        }

        return error;
    }

    const handleSubmit = async () => {
        const error = validate();
        if (!error) {
            try {
                let data
                setLoader(true);
                if (isClients === 'isClients') {
                    data = await post_data("client/send-otp-for-change-email", { email: user_data?.email, new_email: email })
                }
                else if (user_data?.isSubClient) {
                    data = await post_data("sub-client/send-otp-for-change-email", { email: user_data?.email, new_email: email })
                }
                else if (isClients === 'isVendor') {
                    data = await post_data("vendor/send-otp-for-change-email", { email: user_data?.email, new_email: email })
                }
                else if (isClients === 'isUser') {
                    data = await post_data("user/send-otp-for-change-email", { email: user_data?.email, new_email: email })
                }
                else {
                    setIsAdmin(true)
                    data = await post_data("super-admin/send-otp-for-change-email", { email: user_data?.email, new_email: email })
                }

                if (data?.status == true) {
                    setOtpBox(true)
                    setEmailForm(false)
                }
                else {
                    toast.error(data?.response?.data?.message || 'Something Went Wrong!');
                }
                setLoader(false);
            } catch (error) {
                setLoader(false);
            }
        }
    }
    return (
        <Container>
            <LoginBox>
                <FormSection>
                    {
                        emailForm && (
                            <>
                                <Title>Enter New Email</Title>
                                <Subtitle>Please enter new email to get verification code.</Subtitle>
                                <TextField
                                    type="email"
                                    value={email}
                                    label="Email Address"
                                    className={errors.email ? "errorborder" : ""}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }}
                                    onFocus={() => {
                                        setErrors({ ...errors, email: "" })
                                    }}
                                />
                                <small style={{ marginBottom: '20px', color: 'red' }}>{errors.email}</small>

                                <Button variant='contained'
                                    style={{
                                        boxShadow: 'none', padding: '3% 0', background: primaryColor, '&:hover': { backgroundColor: primaryColor }
                                    }}
                                    disabled={loader} onClick={handleSubmit}>Get Verification Code</Button>
                            </>
                        )
                    }

                    {
                        otpBox && (
                            <OtpComponent
                                newEmail={email}
                                isClients={isAdmin ? 'isSuperAdmin' : isClients}
                                type={isAdmin ? 'change-admin-email' : 'change-client-email'}
                                handleResend={handleSubmit}

                            />
                        )
                    }
                </FormSection>
            </LoginBox>
        </Container>
    );
};

export default ChangeAdminEmail;
