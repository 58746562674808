import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useState, useEffect } from "react";
import { createButtonBg, primaryColor } from '../../constant';
import { get_data, post_data } from '../../api';
import { Toaster, toast } from 'react-hot-toast';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
    height: '80vh',
    overflow: 'auto',
};

export default function AdminInventorysModal({ setOpen, open, data, isUpdate, getInvenrotyData }) {
    const [errors, setErrors] = useState({});
    const [companyList, setCompanyList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [formData, setFormData] = useState({ vendorId: '', simNumber: '', imeiNumber: '', deviceUniqueId: '', vehicleNumber: '', network: '', gpsStatus: 'non-active' })
    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    useEffect(() => {
        fetchAllCompany();
        fetchAllVendors()
    }, []);

    console.log('vendor', data)

    useEffect(() => {
        if (data && isUpdate) {
            setFormData({
                clientId: data?.clientId?._id,
                simNumber: data?.simNumber,
                imeiNumber: data?.imeiNumber,
                vehicleNumber: data?.vehicleNumber,
                network: data?.network === 'airtel' || data?.network === 'Airtel' || data?.network === 'AIRTEL' ? 'Airtel' : data?.network === 'vi' || data?.network === 'VI' || data?.network === 'Vi' ? 'Vi' : data?.network === 'bsnl' || data?.network === 'BSNL' || data?.network === 'Bsnl' ? 'BSNL' : data?.network === 'jio' || data?.network === 'Jio' || data?.network === 'JIO' ? 'Jio' : '',
                deviceUniqueId: data?.deviceUniqueId,
                gpsStatus: data?.gpsStatus || 'non-active',
                vendorId: data?.vendorId?._id
            });
        }
    }, [data, isUpdate]);

    const fetchAllVendors = async () => {
        const result = await get_data(`vendor/get-all-vendors-for-admin-inventory`);
        setVendorList(result?.data || []);
    };

    const fetchAllCompany = async () => {
        const result = await get_data(`client/get-all-clients`);
        setCompanyList(result?.data?.clients || []);
    };

    const validation = () => {
        let error = false;
        if (!formData?.simNumber) {
            error = true;
            handleError('Please input SIM Number...', 'simNumber');
        }
        if (!formData?.imeiNumber) {
            error = true;
            handleError('Please input IMEI Number...', 'imeiNumber');
        }
        if (!formData?.network) {
            error = true;
            handleError('Please select Sim Network...', 'network');
        }
        if (!formData?.deviceUniqueId) {
            error = true;
            handleError('Please input Device Unique Id...', 'deviceUniqueId');
        }

        return error;
    };

    const handleClose = () => {
        setErrors({});
        setOpen(false);
    };

    const handleSubmit = async () => {
        const hasError = validation();
        if (!hasError) {
            var body = { ...formData, id: data?._id };
            try {
                let response;
                if (isUpdate) {
                    response = await post_data(`admin-inventory/update-admin-inventory/${body?.id}`, { ...body });
                } else {
                    response = await post_data(`admin-inventory/create-admin-inventory`, { ...body });
                }

                if (response?.status === true) {
                    getInvenrotyData();
                    setFormData('');
                    toast.success(response?.message || 'Inventory created successfully');
                    setOpen(false);
                } else {
                    toast.error(response?.response?.data?.message || 'Something went wrong!');
                }
            } catch (error) {
                toast.error('An error occurred. Please try again.');
            }
        }
    };

    console.log('admin formdata: ', formData)

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            {isUpdate ? 'Update Inventory' : 'Add Inventory'}
                        </Typography>
                        <Grid container spacing={2} style={{ marginTop: 10 }}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    fullWidth
                                    options={companyList}
                                    value={companyList?.find((s) => s?._id === formData?.clientId) || null}
                                    onChange={(event, newValue) => setFormData({ ...formData, clientId: newValue?._id })}
                                    autoHighlight
                                    getOptionLabel={(option) => option.companyName || ''}
                                    renderInput={(params) => <TextField {...params} label="Select Company" fullWidth />}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Autocomplete
                                    fullWidth
                                    options={vendorList}
                                    value={vendorList?.find((s) => s?._id === formData?.vendorId) || null}
                                    onChange={(event, newValue) => setFormData({ ...formData, vendorId: newValue?._id })}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name || ''}
                                    renderInput={(params) => <TextField {...params} label="Select Vendor" fullWidth />}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    value={formData?.vehicleNumber}
                                    label="Vehicle Number"
                                    onChange={(e) => setFormData({ ...formData, vehicleNumber: e.target.value })}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.imeiNumber)}
                                    helperText={errors.imeiNumber}
                                    onFocus={() => handleError('', 'imeiNumber')}
                                    value={formData?.imeiNumber}
                                    label="IMEI Number"
                                    onChange={(e) => setFormData({ ...formData, imeiNumber: e.target.value })}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="text"
                                    error={Boolean(errors.simNumber)}
                                    helperText={errors.simNumber}
                                    onFocus={() => handleError('', 'simNumber')}
                                    value={formData?.simNumber || ''}
                                    label="SIM Number"
                                    onChange={(e) => setFormData({ ...formData, simNumber: e.target.value })}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth >
                                    <InputLabel id="demo-simple-select-label"> Select Sim Network</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={formData?.network || ''}
                                        label="Select Sim Network"
                                        onChange={(e) => setFormData({ ...formData, network: e.target.value })}
                                    >
                                        <MenuItem value={'Vi'}>Vi</MenuItem>
                                        <MenuItem value={'Jio'}>Jio</MenuItem>
                                        <MenuItem value={'Airtel'}>Airtel</MenuItem>
                                        <MenuItem value={'BSNL'}>BSNL</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.deviceUniqueId)}
                                    helperText={errors.deviceUniqueId}
                                    onFocus={() => handleError('', 'deviceUniqueId')}
                                    value={formData?.deviceUniqueId}
                                    label="Device Unique Id"
                                    onChange={(e) => setFormData({ ...formData, deviceUniqueId: e.target.value })}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Status</InputLabel>
                                    <Select value={formData?.gpsStatus} error={Boolean(errors.gpsStatus)}
                                        onFocus={() => handleError('', 'gpsStatus')}
                                        onChange={(e) => setFormData({ ...formData, gpsStatus: e.target.value })}
                                        label="Status">
                                        <MenuItem value="active">Active</MenuItem>
                                        <MenuItem value="non-active">Non-Active</MenuItem>
                                        <MenuItem value="no data">No Data</MenuItem>
                                        <MenuItem value="stock">Stock</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        textTransform: 'capitalize',
                                        color: primaryColor,
                                        border: `1px solid ${primaryColor}`,
                                        borderRadius: 0
                                    }}
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{
                                        textTransform: 'capitalize',
                                        color: '#fff',
                                        background: primaryColor,
                                        borderRadius: 0
                                    }}
                                    onClick={handleSubmit}
                                    fullWidth
                                >
                                    {isUpdate ? 'Update' : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
            {/* <Toaster /> */}
        </div>
    );
}
